import { Injectable } from '@angular/core';
import { Ost, Intense, PuntoPercorsoSuggerito, Percorso, TrattoPercorso, Geometry, RegionData, OstTraduzione, TappaTraduzione } from 'src/app/model/model.interfaces';
import { NgForage } from 'ngforage';
import * as turf from '@turf/helpers';
import * as turf_distance from '@turf/distance';
import * as point_to_line_distance from '@turf/point-to-line-distance';
import * as line_slice from '@turf/line-slice';
import * as bearing from '@turf/bearing';
import * as point_on_line from '@turf/boolean-point-on-line';
import * as line_intersect from '@turf/line-intersect';
import { config } from 'src/environments/config/config';
import { CacheService } from 'src/app/services/cache.service';


@Injectable({ providedIn: 'root' })
export class UtilsService {


  constructor(private ngfg: NgForage, private cache: CacheService) { }


  /////////////////////////////////// OPERAZIONI GENERICHE /////////////////////////////////


  public compareGeneralObj(prev, current): boolean {
    return JSON.stringify(prev) === JSON.stringify(current);
  }

  public clone(object: any): any {
    if (object) {
      return JSON.parse(JSON.stringify(object));
    }
    return object;
  }

  private cleanString(str) {
    return str.replace(/[^a-zA-Z0-9 ]/g, '');
  }

  ///////////////////////////////////  CONVERSIONI ///////////////////////

  public convertToGeoJSONpoint(point) {
    if (point.type && point.type === 'Point') {
      return point;
    }
    if (Array.isArray(point)) {
      return {
        'type': 'Point',
        'coordinates': point
      };
    }
  }

  public GetGeometry(geoJSONobj) {

    if (geoJSONobj.type === 'Feature') {
      return geoJSONobj.geometry;

    }
    return geoJSONobj;

  }


  private featuretoPoint(p) {
    if (Array.isArray(p) || !p) {
      return p;
    }
    if (p.type && p.type === 'Point' || Array.isArray(p)) {
      return p;
    }
    if (p.type && p.type === 'Feature') {
      return this.featuretoPoint(p.geometry);
    }
    return p;
  }

  public featureToArray(p) {
    if (Array.isArray(p) || !p) {
      return p;
    }
    if (p.type && p.type === 'Point') {
      return p.coordinates;
    }
    if (p.type && p.type === 'Feature') {
      return this.featureToArray(p.geometry);
    }
    return p;
  }

  //////////////////////////////// DETTAGLI DI UNA GEOMETRIA  ////////////////////////////

  public getLastCoords(geoJSONobj) {
    if (!geoJSONobj || Array.isArray(geoJSONobj)) { return geoJSONobj; }
    let res;
    if (geoJSONobj.type === 'MultiLineString') {
      const lastLine = geoJSONobj.coordinates[geoJSONobj.coordinates.length - 1];
      res = lastLine[lastLine.length - 1];
    } else if (geoJSONobj.type === 'LineString') {
      res = geoJSONobj.coordinates[geoJSONobj.coordinates.length - 1];
    } else if (geoJSONobj.type === 'Point') {
      res = geoJSONobj.coordinates;
    } else if (geoJSONobj.type === 'Feature') {
      return this.getLastCoords(geoJSONobj.geometry);
    }
    return res;
  }

  public getFirstCoords(geoJSONobj) {
    if (Array.isArray(geoJSONobj) || !geoJSONobj) { return geoJSONobj; }
    let res;
    if (geoJSONobj.type === 'MultiLineString') {
      const firstLine = geoJSONobj.coordinates[0];
      res = firstLine[0];
    } else if (geoJSONobj.type === 'LineString') {
      res = geoJSONobj.coordinates[0];
    } else if (geoJSONobj.type === 'Point') {
      res = geoJSONobj.coordinates;
    } else if (geoJSONobj.type === 'Feature') {
      return this.getFirstCoords(geoJSONobj.geometry);
    }
    return res;
  }

  public getCoords(geoJSONobj) {
    // wrapper
    return this.getFirstCoords(geoJSONobj);
  }

  public calculateDirection(geoJSONobj, start) {
    let line;
    if (geoJSONobj.type === 'MultiLineString') {
      if (start) {
        line = geoJSONobj.coordinates[0];
      } else {
        line = geoJSONobj.coordinates[geoJSONobj.coordinates.length - 1];
      }
    } else if (geoJSONobj.type === 'LineString') {
      line = geoJSONobj.coordinates;
    } else {
      return 0;
    }
    if (line.length >= 2) {
      let p1, p2;
      if (start) {
        p1 = line[0];
        p2 = line[1];
      } else {
        p1 = line[line.length - 1];
        p2 = line[line.length - 2];
      }
      return turf.bearingToAzimuth(bearing.default(p1, p2, { final: false }));
    }
    return 0;
  }

  /////////////////////////////////// OPERAZIONI SU PERCORSO e INTENSE ////////////////////////


  public isIntenseInterrupted(intense: Intense) {
    let res = false;
    this.getOstsList(intense).forEach(o => res = (o.status === 0) ? true : res);
    return res;
  }

  public getTappeListTrad(intense): TappaTraduzione[] {
    const res = [];
    intense.tappe.forEach(tappa => {
      res.push({ name: tappa.name, id: tappa.id });
    });
    return res;
  }

  public getOstsListTrad(intense): OstTraduzione[] {
    const res = [];
    this.getOstsList(intense).forEach(ost => {
      res.push({ id: ost.id, descrizione: ost.descrizione });
    });
    return res;
  }

  public getOstsList(intense): Ost[] {
    const res = [];
    // ost già modificati
    if (intense.osts) {
      intense.osts.forEach(ost => {
        res.push(ost);
      });
    }

    // ost dentro le tappe
    if (intense.tappe) {
      intense.tappe.forEach(tappa => {
        if (tappa.osts) {
          tappa.osts.forEach(ost => {
            if (!res.find(x => x.id === ost.id)) {
              res.push(ost);
            }
          });
        }
        if (tappa.percorso && tappa.percorso.tratti) {
          // ost nei tratti del percorso
          tappa.percorso.tratti.forEach(tratto => {
            const ost = tratto.ost;
            if (ost && ost.id && !res.find(x => x.id === ost.id)) {
              res.push(ost);
            }
          });
        }
      });
    }
    return res;
  }

  public async calculateIndex(intense: Intense, ost: Ost) {
    const currentTappaIdx = intense.currentTappa;
    if (!intense) {
      return -1;
    }


    const ostGeoJSON = await this.ngfg.getItem('' + ost.id);
    // ostGeoJSON['properties'] = { id: ost.id };

    // const osts = intense.tappe[intense.tappe.length - 1].osts;

    if (!intense.tappe) {
      return; // TODO: remove this
    }

    let tappaIdx = intense.tappe.length - 1;
    if (currentTappaIdx != null && currentTappaIdx >= 0) {
      tappaIdx = currentTappaIdx;
    }
    const osts = intense.tappe[tappaIdx].osts;

    if (!osts.length) { if (this.isOstTratta(ost)) { return 0; } else { return -1; } }

    for (const tappaOst of osts) {
      if (!this.isOstTratta(tappaOst)) {
        continue;
      }
      if (this.isOstTratta(ost)) {
        const tratto = await this.ngfg.getItem('' + tappaOst.id);

        const lastTrattoEnd = turf.point(this.getLastCoords(tratto));
        const firstTrattoStart = turf.point(this.getFirstCoords(tratto));
        const ostStart = turf.point(this.getFirstCoords(ostGeoJSON));
        const ostEnd = turf.point(this.getLastCoords(ostGeoJSON));

        const fromLastTrattoEndToOstStart = turf_distance.default(lastTrattoEnd, ostStart, { units: 'kilometers' });
        const fromLastTrattoEndToOstEnd = turf_distance.default(lastTrattoEnd, ostEnd, { units: 'kilometers' });
        const fromOstEndToFirstTrattoStart = turf_distance.default(firstTrattoStart, ostEnd, { units: 'kilometers' });
        const fromOstStartToFirstTrattoStart = turf_distance.default(firstTrattoStart, ostStart, { units: 'kilometers' });

        if (Math.min(fromLastTrattoEndToOstStart, fromLastTrattoEndToOstEnd,
          fromOstEndToFirstTrattoStart, fromOstStartToFirstTrattoStart) <= config.DISTANZA_PERCORSO_ATTRATTORE_KM) {

          return 1;
        }
      } else {
        const punto = turf.point(ostGeoJSON['coordinates']);
        const geoJSONobj = await this.ngfg.getItem('' + tappaOst.id);
        const distance = this.calculateDistanceFromTratto(geoJSONobj, punto);
        if (distance <= config.DISTANZA_PERCORSO_ATTRATTORE_KM) { return 1; }
      }
    }

    return -1;
  }


  public isNearPercorso(
    point: Geometry, percorso: Percorso
  ): boolean {
    // controlla tutti i tratti del percorso e se la distanza dal punto è < della distanza massima ritorna true
    if (percorso && percorso.tratti) {
      for (const trattaPercorso of percorso.tratti) {
        if (point) {
          const d = this.calculateDistanceFromTratto(trattaPercorso.tratto, point);
          if (config.DISTANZA_PERCORSO_ATTRATTORE_KM > d) {
            return true;
          }
        }
      }
    }
    return false;
  }

  public async isNearTratto(tratto, ost: Ost) {
    const jsonobj = await this.getOstGeometry(ost);
    const d = this.calculateDistanceFromTratto(tratto.tratto, jsonobj);
    if (config.DISTANZA_PERCORSO_ATTRATTORE_KM > d) {
      return true;
    }
    return false;
  }

  // public getFirstTrattoOfIntense(intense: Intense, tappaIdx: number) {
  //   if (!intense) {
  //     return null;
  //   }
  //   return this.getFirstTratto(intense.tappe[tappaIdx].osts);
  // }


  // public getLastTrattoOfIntense(intense: Intense, tappaIdx: number) {
  //   if (!intense) {
  //     return null;
  //   }
  //   return this.getLastTratto(intense.tappe[tappaIdx].osts);
  // }


  ///////////////////////// OPERAZIONI TRA LINEE E PUNTI /////////////////////////////


  public isEstremo(linea: any, punto: any): boolean {
    const inizio = this.getFirstCoords(linea);
    const fine = this.getLastCoords(linea);
    // console.log('TCL: UtilsService -> estremo --- linea,inizio,fine,punto', linea, inizio, fine, punto);
    return this.isSamePoint(punto, inizio) || this.isSamePoint(punto, fine);
  }

  public calculateDistanceFromTratto(trattoGeojson, pointGeojson) {
    if (!pointGeojson || !trattoGeojson) { return 0; }
    if (trattoGeojson.type === 'LineString') {
      const from = turf.lineString(trattoGeojson['coordinates']);
      // return this.distance(pointGeojson, from);
      return point_to_line_distance.default(pointGeojson, from);
    } else if (trattoGeojson.type === 'MultiLineString') {
      let distance = null;
      for (let i = 0; i < trattoGeojson.coordinates.length; i++) {
        const string = trattoGeojson.coordinates[i];
        const from = turf.lineString(string);
        // const d = this.distance(pointGeojson, from);
        const d = point_to_line_distance.default(pointGeojson, from);
        if (distance === null || distance > d) {
          distance = d;
        }
      }
      return distance;
    } else {
      if (trattoGeojson.type === 'Feature') {
        return this.calculateDistanceFromTratto(trattoGeojson.geometry, pointGeojson);
      } else {
        return 0;
      }
    }
  }

  public isOnLine(puntoRaw, linea) {
    if (!puntoRaw || !linea) { return false; }
    const punto = this.getCoords(puntoRaw);
    if (linea.type && linea.type === 'LineString') {
      return (point_to_line_distance.default(punto, linea) < config.DISTANZA_INCROCIO_KM);
    }
    if (!punto || !linea) {
      console.log('ERROR: UtilsService -> isOnLine -> punto || !linea', punto, linea);

    }
    return point_on_line.default(punto, linea);
  }

  public isSamePoint(p1, p2) {
    if (p1 && p2) {
      return this.distance(p1, p2) === 0;
    }
    return false;
  }

  public intersezione(linea1, linea2) {
    const allIntersect = line_intersect.default(linea1, linea2);
    if (allIntersect.features.length < 2) { return allIntersect; }
    const res = turf.featureCollection<turf.Point>([]);
    const first = allIntersect.features[0];
    res.features.push(first);
    const last = allIntersect.features[allIntersect.features.length - 1];
    let precedente = first;
    allIntersect.features.forEach(feature => {
      if (this.distance(precedente, feature) > config.DISTANZA_INCROCIO_KM) { res.features.push(feature); }
      precedente = feature;
    });
    // ? aggiungere l'ultimo
    if (res.features[res.features.length - 1] !== last) { res.features.push(last); }
    return res;
  }

  public distance(p1, p2): number {
    const p1p = this.featuretoPoint(p1);
    const p2p = this.featuretoPoint(p2);
    if (p1p && p2p) {
      return turf_distance.default(p1p, p2p, { units: 'kilometers' });
    } 
    return 0;
  }

  ////////////////////// OPERAZIONI SU OST ////////////////////////////

  public isOstTratta(ost) {
    return ost.type === 'ost_sentiero_percorso';
  }

  async isOstSequenziale(ost1: Ost, ost2: Ost) {
    if (!ost1 || !ost2) {
      return true;
    }
    const geoJSONobj1 = await this.getOstGeometry(ost1);
    const geoJSONobj2 = await this.getOstGeometry(ost2);

    const ost2Start = turf.point(this.getFirstCoords(geoJSONobj2));
    const ost1End = turf.point(this.getLastCoords(geoJSONobj1));
    const dist = this.distance(ost2Start, ost1End);
    return dist < config.DISTANZA_LIMITE_CONCATENABILI_KM;
  }

  async ostListConcatenabile(osts1: Ost[], osts2: Ost[]) {
    if (!osts1 || osts1.length === 0 || !osts2 || osts2.length === 0) {
      return true;
    }
    const lastTratto1 = this.getLastTratto(osts1);
    const firstTratto2 = this.getFirstTratto(osts2);
    return await this.isOstSequenziale(lastTratto1, firstTratto2);
  }

  getFirstTratto(osts: Ost[]): Ost {
    if (osts.length === 0) {
      return null;
    }
    let firstTratto = osts[0];
    for (const i in osts) {
      if (this.isOstTratta(osts[i])) {
        firstTratto = osts[i];
        break;
      }
    }
    return firstTratto;
  }

  getLastTratto(osts: Ost[]): Ost {
    return this.getFirstTratto(osts.slice().reverse());
  }


  public async isEstremiOst(ost: Ost, p1, p2): Promise<boolean> {
    const line = await this.getOstGeometry(ost);
    return this.isEstremo(line, p1) && this.isEstremo(line, p2);
  }

  public async getSegmento(ost: Ost, startPoint: Geometry, endPoint: Geometry, isReverse: boolean): Promise<Geometry> {
    const line = await this.getOstGeometry(ost);
    // if (!isReverse) {
    return line_slice.default(this.getCoords(startPoint), this.getCoords(endPoint), line);
    // } else {
    //   return line_slice.default(this.getCoords(endPoint), this.getCoords(startPoint), line);
    // }
  }


  /////////////////  RECUPERO DATI DA STORAGE //////////////////////////


  public async getLocal(key: string): Promise<any> {
    return await this.ngfg.getItem(key);
  }

  public async setLocal(key: string, value: any) {
    return await this.ngfg.setItem(key, value);
  }

  public async getOstGeometry(ost): Promise<any> {
    if (!ost) { return null; }
    return this.getGenericGeometry(ost.id);
  }

  public async getGenericGeometry(id): Promise<any> {
    if (!id) { return null; }
    // sistema di caching delle geometrie, inizialemente salvate sull'ngForage
    const cached = this.cache.get(id);
    if (cached) {
      return cached;
    }
    const geom = await this.ngfg.getItem('' + id);
    this.cache.set(id, geom);
    return geom;
  }

  public async setOstGeometry(ostId, geometry): Promise<any> {
    this.cache.invalidate(ostId);
    return this.ngfg.setItem('' + ostId, geometry);
  }

  public async setGenericGeometry(id, geometry): Promise<any> {
    this.cache.invalidate(id);
    return this.ngfg.setItem('' + id, geometry);
  }

  public async getRegionData(regionName): Promise<RegionData> {
    const cached = this.cache.get(this.regionKey(regionName));
    if (cached) {
      return cached;
    }
    const data = await this.ngfg.getItem(this.regionKey(regionName));
    const center = data['center']['value'].replace('POINT (', '').replace('POINT(', '').replace(')', '').split(' ');
    const regionData = { geometry: data['geometry'], center: center };
    this.cache.set(this.regionKey(regionName), regionData);
    return regionData;
  }

  public async setRegionData(regionName, geometry, center): Promise<any> {
    this.cache.invalidate(this.regionKey(regionName));
    return this.ngfg.setItem(this.regionKey(regionName), { geometry: geometry, center: center });
  }

  private regionKey(regionName) {
    const cleanName = this.cleanString(regionName);
    return 'region-' + cleanName;
  }


  /////////////////  TIPOLOGIA DEGLI ATTRATTORI //////////////////////////

  public ostGetTipologia(ost: Ost): string {
    if (this.isOstTratta(ost)) {
      return 'tratta';
    }
    if (!ost.tipologia || ost.tipologia.length === 0) {
      return 'ignoto';
    }
    const tipologia = ost.tipologia[0].name.toLocaleLowerCase();
    if (tipologia.indexOf('natur') >= 0) {
      return 'natura';
    }
    if (tipologia.indexOf('montagn') >= 0) {
      return 'montagna';
    }
    if (tipologia.indexOf('forest') >= 0 || tipologia.indexOf('alber') >= 0) {
      return 'foresta';
    }
    if (tipologia.indexOf('panoram') >= 0) {
      return 'panorama';
    }
    if (tipologia.indexOf('lago') >= 0 || tipologia.indexOf('fonte') >= 0 || tipologia.indexOf('cascata') >= 0) {
      return 'acqua';
    }
    if (tipologia.indexOf('edificio') >= 0 || tipologia.indexOf('citt') >= 0) {
      return 'edificio';
    }
    if (tipologia.indexOf('archeolog') >= 0 || tipologia.indexOf('museo') >= 0) {
      return 'cultura';
    }
    if (tipologia.indexOf('scogliera') >= 0 || tipologia.indexOf('spiaggia') >= 0) {
      return 'mare';
    }
    if (tipologia.indexOf('monumento') >= 0) {
      return 'monumento';
    }


  }
}
