import { Action } from '@ngrx/store';

export enum SearchIntenseActionTypes {
    SearchIntense = '[SearchIntense] SEARCH',
    SearchIntenseSuccess = '[SearchIntense] SEARCH_SUCCESS',
    SearchIntenseFail = '[SearchIntense] SEARCH_FAIL',
    SetQuery = '[SearchIntense] SET_QUERY',
    SetBoundingBox = '[SearchIntense] SET_BOUNDINGBOX',
    SetFilter = '[SearchIntense] SET_FILTER',
    SetSize = '[SearchIntense] SET_SIZE',
    SetPage = '[SearchIntense] SET_PAGE',
    SetPageSuccess = '[SearchIntense] SET_PAGE_SUCCESS',
    ClearFilters = '[SearchIntense] CLEAR_FILTERS',
    SelectIntense = '[SearchIntense] SELECT_INTENSE',
    DeleteIntense = '[SearchIntense] DELETE_INTENSE',
    DeleteIntenseSuccess = '[SearchIntense] DELETE_INTENSE_SUCCESS',
    DeleteIntenseFail = '[SearchIntense] DELETE_INTENSE_FAIL',
    SetUid = '[SearchIntense] SET_UID',
    SetStatus = '[SearchIntense] SET_STATUS',
}

export class SearchIntense implements Action {
    readonly type = SearchIntenseActionTypes.SearchIntense;
    constructor(public payload: any) {}
}

export class SearchIntenseSuccess implements Action {
    readonly type = SearchIntenseActionTypes.SearchIntenseSuccess;
    constructor(public payload: any) {}
}

export class SearchIntenseFail implements Action {
    readonly type = SearchIntenseActionTypes.SearchIntenseFail;
    constructor(public payload?: any) {}
}

export class SetFilter implements Action {
    readonly type = SearchIntenseActionTypes.SetFilter;
    constructor(public payload?: any) {}
}

export class SetQuery implements Action {
    readonly type = SearchIntenseActionTypes.SetQuery;
    constructor(public payload?: any) {}
}

export class SetSize implements Action {
    readonly type = SearchIntenseActionTypes.SetSize;
    constructor(public payload?: any) {}
}

export class SetBoundingBox implements Action {
    readonly type = SearchIntenseActionTypes.SetBoundingBox;
    constructor(public payload?: any) {}
}

export class ClearFilters implements Action {
    readonly type = SearchIntenseActionTypes.ClearFilters;
    constructor(public payload?: any) {}
}

export class SetPage implements Action {
    readonly type = SearchIntenseActionTypes.SetPage;
    constructor(public payload?: any) {}
}

export class SetPageSuccess implements Action {
    readonly type = SearchIntenseActionTypes.SetPageSuccess;
    constructor(public payload?: any) {}
}

export class SelectIntense implements Action {
    readonly type = SearchIntenseActionTypes.SelectIntense;
    constructor(public payload?: any) {}
}
export class DeleteIntense implements Action {
    readonly type = SearchIntenseActionTypes.DeleteIntense;
    constructor(public payload?: any) {}
}

export class DeleteIntenseSuccess implements Action {
    readonly type = SearchIntenseActionTypes.DeleteIntenseSuccess;
    constructor(public payload?: any) {}
}

export class DeleteIntenseFail implements Action {
    readonly type = SearchIntenseActionTypes.DeleteIntenseFail;
    constructor(public payload?: any) {}
}

export class SetUid implements Action {
    readonly type = SearchIntenseActionTypes.SetUid;
    constructor(public payload?: any) {}
}

export class SetStatus implements Action {
    readonly type = SearchIntenseActionTypes.SetStatus;
    constructor(public payload?: any) {}
}

export type SearchIntenseActions =
SearchIntense |
SearchIntenseSuccess |
SearchIntenseFail |
SetFilter |
SetQuery |
SetBoundingBox |
SetSize |
SetPage |
SetPageSuccess |
ClearFilters |
SelectIntense |
DeleteIntense |
DeleteIntenseSuccess |
DeleteIntenseFail |
SetUid |
SetStatus;
