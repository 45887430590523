import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
// import { OAuthService } from 'angular-oauth2-oidc';
import { Store } from '@ngrx/store';
import { FetchAllSchede, FetchAllOst, FetchAllRegioni, FetchPeriods } from 'src/app/logic/app-data';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import { AsrAuthGuard } from 'src/app/services/auth.service';


@Component({
    selector: 'app-login-intense',
    templateUrl: './login-intense.component.html',
})

export class LoginIntenseComponent implements OnInit {
    public hideLogin = false;
    constructor(
        private router: Router,
        private store: Store<any>,
        // private oAuthService: OAuthService,
        private route: ActivatedRoute,
        private api: ApiService,
        private authService: AsrAuthGuard
    ) { }


    username: FormControl = new FormControl('', [Validators.required]);
    password: FormControl = new FormControl('', [Validators.required]);
    loginForm: FormGroup = new FormGroup({ 'password': this.password, 'username': this.username }, []);
    ngOnInit() {
        if (this.authService.isLogged()) {
            this.hideLogin = true;
            this.loadData();
        } else {
            // check idm code
            const code = this.route.snapshot.queryParamMap.get('code');
            if (code) {
                this.hideLogin = true;

                this.api.loginIdm(code).subscribe(res => {

                    if (res.token) {
                        const uid = res.user_data.uid[0].value;
                        this.authService.setToken(res.token, uid);
                        this.authService.setUserData(res.user_data);
                        this.loadData();
                    }
                });
            }
        }
    }
    login(): void {
        this.authService.login(this.username.value, this.password.value).then((resp) => {
            // Loading data about the user
            this.loadData();
        }).then(() => {
            // Using the loaded user data
        });
    }

    loadData() {
        // load data about the user
        this.loadUserData();

        // load intenses data
        this.store.dispatch(new FetchAllSchede());
        this.store.dispatch(new FetchAllOst());
        this.store.dispatch(new FetchAllRegioni());
        this.store.dispatch(new FetchPeriods());
    }

    loadUserData() {
        if (!this.authService.isUserDataSet()) {
            this.authService.getUserId().then(id => {
                this.api.getUserDetails(id).subscribe(x => {
                    let data = x;
                    if (x['userData']) {
                        data = x['userData'];
                    }
                    this.authService.setUserData(data);
                });
            })
        }
    }

    loginIdm() {
        // redirect
        const url = environment.authEndPoint.idmUrl;
        console.log('-----------: LoginIntenseComponent -> loginIdm -> url', url);
        window.location.href = url;
    }
}

