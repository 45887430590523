export const environment = {
  production: false,
  log: true,
  sin2api: 'https://dev.api.intense.netseven.it/ras_api/ost?_format=json',
  graphqlUrl: 'https://dev.api.intense.netseven.it/api/v1.0/graphql',
  oauth2: {
    clientId: '129e2d34-a6fc-45f3-8a6d-068199905ca1',
    clientSecret: '123',
    scope: 'ost_editor',
    tokenUrl: 'https://dev.api.intense.netseven.it/oauth/token',
    logoutUrl: 'https://dev.intense.netseven.it/login',
  },
  sessionUrl: 'https://dev.api.intense.netseven.it/session/token',
  userDetailUrl: 'https://dev.api.intense.netseven.it/user/${userID}?_format=json',
  authEndPoint: {
    responseUrl: 'https://dev.api.intense.netseven.it/api/v1.0/idm_login/',
    idmUrl: 'https://accessmanager.demo.sardegnait.it/access-manager/oauth/authorize?response_type=code&client_id=dev.sin2.intense.regionesardegna.it&redirect_uri=https://dev.intense.netseven.it/sso_return&scope=read',
  }
};
