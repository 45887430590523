import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Intense, Ost, Percorso, TrattoPercorso, Tappa, Periodo, Traduzione } from 'src/app/model/model.interfaces';
import { Observable, from, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PercorsoService } from './percorso.service';
import { UtilsService } from './utils.service';
import { TranslateService } from '@ngx-translate/core';
import { concatMap, map, last, toArray } from 'rxjs/operators';
import * as moment from 'moment';


@Injectable({ providedIn: 'root' })
export class ApiService {
    defaultTappaName: '';

    SAVE_URL = environment.sin2api;
    FRUIZIONE_FORMAT = 'YYYY-MM-DD hh:mm:ss';
    DATAPRECISA_FORMAT = 'YYYY-MM-DD';

    STATO_PUBBLICATA = 'pubblicata';
    STATO_VALIDAZIONE = 'scheda_pronta';
    STATO_DRAFT = 'draft';

    CLASSE_TRADUZIONE_VUOTO = 'vuoto';
    CLASSE_TRADUZIONE_NONCOMPLETO = 'noncompleto';
    CLASSE_TRADUZIONE_COMPLETO = 'completo';

    constructor(
        private http: HttpClient,
        private percorso: PercorsoService,
        private utils: UtilsService,
        private translate: TranslateService
    ) {
        setTimeout(() => {
            translate.get('common.defaultstagename').subscribe(res => this.defaultTappaName = res);
        }, 500);
    }

    public loginIdm(code): Observable<any> {
        return this.http.get(environment.authEndPoint.responseUrl + code);
    }

    public deleteIntense(intenseId: number): Observable<any> {

        const geojson = [{
            'type': 'FeatureCollection',
            'features': [
                {
                    'type': 'Feature',
                    'properties': {
                        'class': 'Scheda',
                        'delete': true,
                        'nid': intenseId
                    }
                }
            ]
        }];
        return this.http.post(this.SAVE_URL, geojson);
    }


    saveIntense(intenseOrigin: Intense, traduzioni: Traduzione[], currentLang: string): Observable<any> {

        const intense: Intense = this.utils.clone(intenseOrigin);


        return this.http.post(this.SAVE_URL, this.createIntenseArray(intense, currentLang)).pipe(concatMap(res => {
            if (!res['errors'] || !res['errors'].length) {

                const saves = [];
                for (const trad in traduzioni) {
                    if (traduzioni.hasOwnProperty(trad)) {
                        if (trad === currentLang || !traduzioni[trad].modified) { console.log('ignore trad', trad); continue; }

                        /// inserimento delle traduzioni nell'intense
                        this.updateTranslation(intense, traduzioni[trad], res['affected_items']);
                        const saveArray = this.createIntenseArray(intense, trad);

                        // chiamate al servizio di salvataggio
                        saves.push(
                            // this.http.post(this.SAVE_URL, saveArray)
                            saveArray
                        );
                    }
                }
                console.log('-------: saveArray', saves);
                // tutte le chiamate insieme
                return from(saves).pipe(concatMap(sa => this.http.post(this.SAVE_URL, sa)), toArray());
            } else {
                return of(res);
            }
        })
        );
    }

    createIntenseArray(intense, trad) {
        // creazione geojson con informazioni generali
        const geojson1 = {
            'type': 'FeatureCollection',
            'features': this.geneateAllFeatures(intense, trad),
            'properties': { 'locale': trad, 'stato': intense.stato }
        };
        // creazione geojson con informazioni del percorso
        const geojson2 = {
            'type': 'FeatureCollection',
            'features': this.geneateTrackFeatures(intense)
        };
        const saveArray = [geojson1, geojson2];

        console.log('--- --------: saveArray ' + trad, saveArray);

        return saveArray;
    }




    updateTranslation(intense: Intense, translation: Traduzione, affected) {
        const tappe: any[] = affected.tappe;
        const intenseId: any[] = affected.intense;
        console.log('-----------: updateTranslation -> translation', translation);
        intense.descrizione = translation.descrizione;
        intense.name = translation.name ? translation.name : intense.name;
        intense.stato = translation.stato;
        if (intenseId && Array.isArray(intenseId) && intenseId.length) { intense.id = intenseId[0]; }
        if (intense.osts) {
            intense.osts.forEach(o => this.updateTranslationOst(o, translation.osts));
        }

        if (intense.tappe) {
            intense.tappe.forEach((tappa, idx) => {

                tappa.id = tappe[idx];
                // const tappaTrad = translation.tappe.find(t => t.id === tappa.id);
                const tappaTrad = translation.tappe[idx];
                if (tappaTrad) {
                    tappa.name = tappaTrad.name ? tappaTrad.name : tappa.name;
                }
                if (tappa.osts) {
                    tappa.osts.forEach(ost => {
                        this.updateTranslationOst(ost, translation.osts);
                    });
                }
                if (tappa.percorso && tappa.percorso.tratti) {
                    tappa.percorso.tratti.forEach(tr => {
                        this.updateTranslationOst(tr.ost, translation.osts);
                    });
                }
            });
        }
    }

    updateTranslationOst(o: any, osts: any) {
        const ost = null;
        if (o && o.id) {
            osts.find(x => x.id === o.id);
            o.descrizione = ost ? ost.descrizione : o.descrizione;
        }
    }

    private geneateAllFeatures(intense: Intense, trad: string) {
        const data = [];
        data.push(this.createIntenseFeature(intense, trad));
        for (let i = 0; i < intense.tappe.length; i++) {
            const tappaData = this.createTappaFeature(intense.tappe[i], i, trad);
            data.push(tappaData);
            // const tappaId = tappaData.properties.tappaId; // i

            const osts = this.getOstOfTappa(intense, i);
            for (let j = 0; j < osts.length; j++) {
                const element: Ost = osts[j];
                const geoOst = this.createOstFeature(element, i, trad);
                data.push(geoOst as any);
            }
        }
        // console.log('SAVE EDIT INTENSE -> data', data);
        return data;
    }

    private geneateTrackFeatures(intense: Intense) {
        const data = [];
        for (let i = 0; i < intense.tappe.length; i++) {
            this.generatePercorsoTrattoJSON(intense.tappe[i].percorso, i).forEach(element => {
                data.push(element as any);
            });
        }
        // console.log('SAVE TRACK INTENSE -> data', data);
        return data;
    }

    private getOstOfTappa(intense: Intense, tappa) {
        // console.log('TCL: ApiService -> getOstOfTappa -> intense', intense);
        const res = [];

        // recupero ost della tappa (controllando se non sia stata modificata)
        if (intense.tappe && intense.tappe[tappa] && intense.tappe[tappa].osts) {
            intense.tappe[tappa].osts.forEach(ost => {
                let ins = null;
                if (intense.osts && Array.isArray(intense.osts)) {
                    ins = intense.osts.find(o => o.id === ost.id);
                }
                if (!ins) { ins = ost; }
                res.push(ins);
            });
        }

        // recupero ost dei percorsi (controllando che non sia stato modificato)
        if (intense.tappe[tappa].percorso) {
            intense.tappe[tappa].percorso.tratti.forEach(
                tratto => {
                    if (tratto.ost && !res.find(o => o.id === tratto.ost.id)) {
                        let ins = null;
                        if (intense.osts && Array.isArray(intense.osts)) {
                            ins = intense.osts.find(o => o.id === tratto.ost.id);
                        }
                        if (!ins) { ins = tratto.ost; }
                        res.push(ins);
                    }
                });
        }

        return res;
    }
    private fakeGeometry() {
        return {
            'type': 'Point',
            'coordinates': [0, 0]
        };
    }

    private createIntenseFeature(intense: Intense, trad: string) {
        // console.log('-----------: ApiService -> createIntenseFeature -> intense', intense);
        return {
            'type': 'Feature',
            'properties': {
                'field_fonte_dati': [
                    'Frontend editoriale'
                ],
                'class': 'Scheda',
                'title': intense.name,
                'body': intense.descrizione,
                'nid': !!intense.id ? intense.id : undefined,
                'tipoFondo': [],
                'tipologia': [],
                'ambito': [],
                'fruizione': [],
                'galleria': [],
                'immaginePrincipale': [],
                'allegati': intense.allegati,
                'immagini': intense.immagine,
                // 'disponibilità': intense.disponibilita,
                // 'dataUltimaVerifica': intense.dataUltimaVerifica,
                // 'dataImportazione': intense.dataImportazione,
                // 'dataScadenza': intense.dataScadenza,
                // 'fineIndisponibilita': intense.fineIndisponibilita,
                // 'inizioIndisponibilita': intense.inizioIndisponibilita,
                // 'idExt': intense.idExt,
                'mezziTrasporto': intense.mezziTrasporto,
                'periodoFruizione': this.creaPeriodiFruizione(intense.periodoFruizione),
                'stato': intense.stato,
                'locale': trad
            }
        };
    }

    private creaPeriodiFruizione(fruizione: Periodo[]) {
        if (!fruizione) {
            return -1;
        }
        const res = [];
        fruizione.forEach(periodo => {
            const x = {};
            if (periodo.holiday_label && periodo.holiday_label !== '0') {
                x['festivita'] = periodo.holiday_label;
                x['dataEsatta'] = 0;
            } else if (periodo.is_precise) {
                x['dataEsatta'] = 1;
                x['dataEsattaDa'] = moment(periodo.fruizione_from ? periodo.fruizione_from : periodo.fruizione_from_precise).format(this.DATAPRECISA_FORMAT);
                x['dataEsattaA'] = moment(periodo.fruizione_to ? periodo.fruizione_to : periodo.fruizione_to_precise).format(this.DATAPRECISA_FORMAT);
            } else {
                x['dataEsatta'] = 0;
                x['periodoFruizioneDa'] = moment(periodo.fruizione_from).format(this.FRUIZIONE_FORMAT);
                x['periodoFruizioneA'] = moment(periodo.fruizione_to).format(this.FRUIZIONE_FORMAT);
            }
            res.push(x);
        });

        return res;
    }

    private createTappaFeature(tappa, index, trad: string) {
        return {
            'type': 'Feature',
            'properties': {
                'class': 'Tappa',
                'title': tappa.name, // ? tappa.name : (this.defaultTappaName + (index + 1)),
                'body': tappa.descrizione,
                'tappaId': index,
                'nid': tappa.id, // index,
                'locale': trad
            },
            // 'geometry': this.fakeGeometry()
        };
    }

    private createOstFeature(ost: Ost, tappa_id, trad: string) {
        return {
            'type': 'Feature',
            'properties': {
                'field_fonte_dati': [
                    'Frontend editoriale'
                ],
                'nid': ost.id,
                'class': 'Ost',
                'title': ost.name,
                'body': ost.descrizione,
                'tappaId': tappa_id,
                'disponibilita': 1,
                // 'ultimaVerifica': '2012-04-23T18:25:43.511Z',
                // 'dataImportazione': '2012-04-23T18:25:43.511Z',
                // 'dataScadenza': null,
                // 'fineIndisponibilita': null,
                // 'inizioIndisponibilita': null,
                'tipologia': !!ost.tipologia ? ost.tipologia : [],
                'riconoscimenti': [],
                'visitabile': ost.visitabile,
                'allegati': [],
                'idCastasto': ost.identificativoCatasto,
                'subclass': ost.type,
                'accessibilita': !!ost.accessibilitaDisabili ? ost.accessibilitaDisabili : [],
                'contatti': [],
                'field_id_ext': ost.identificativo,
                'immagini': ost.immagine,
                'immaginePrincipale': [],
                'locale': trad
            },
            // 'geometry': this.fakeGeometry()
        };
    }

    private generateTrattoGeoJSON(tratto: TrattoPercorso, tappa_idx) {
        return {
            'type': 'Feature',
            'properties': {
                'nid': tratto['id'],
                'ostid': tratto.ost ? tratto.ost.id : 0,
                'tappaId': tappa_idx,
                'parziale': tratto.parziale
            },
            'geometry': this.utils.GetGeometry(tratto.tratto)
        };
    }

    private generatePuntoFinaleTrattoGeoJSON(tratto: TrattoPercorso, tappa_idx) {
        return {
            'type': 'Feature',
            'properties': {
                'nid': tratto['id'],
                'ostid': tratto.ost ? tratto.ost.id : 0,
                'tappaId': tappa_idx,
                'parziale': tratto.parziale
            },
            'geometry': this.utils.convertToGeoJSONpoint(tratto.ultimoPunto)
        };
    }

    private generatePuntoInizialeTrattoGeoJSON(perc: Percorso, tappa_idx) {
        return {
            'type': 'Feature',
            'properties': {
                'ostid': perc.ostIniziale && perc.ostIniziale.id ? perc.ostIniziale.id : 0,
                'tappaId': tappa_idx
            },
            'geometry': this.utils.convertToGeoJSONpoint(perc.inizio),
        };
    }

    private generatePercorsoTrattoJSON(percorso: Percorso, tappa_idx) {
        const res = [];
        if (percorso) {
            res.push(this.generatePuntoInizialeTrattoGeoJSON(percorso, tappa_idx));
            percorso.tratti.forEach(tratto => {
                res.push(this.generateTrattoGeoJSON(tratto, tappa_idx));
                res.push(this.generatePuntoFinaleTrattoGeoJSON(tratto, tappa_idx));
            }
            );
        }
        return res;
    }

    /// Funzione che completa i dati di una scheda intense. In caso di una scheda 'vecchia' la trasforma in una nuova, mentre per le nuove ricostruisce il percorso a partire dal geojson
    public async completeIntense(incompleteIntense: Intense): Promise<Intense> {

        if (incompleteIntense && incompleteIntense.geojson && incompleteIntense.geojson.value) {
            console.log(' NEW format Intense ----- Parse', incompleteIntense);
            return this.parseIntese(incompleteIntense);
        }

        if (incompleteIntense && (!incompleteIntense.geojson || !incompleteIntense.geojson.value)) {
            console.log(' OLD format Intense ----- Import', incompleteIntense);
            return this.importFromOld(incompleteIntense);
        }

        return incompleteIntense;
    }

    public async importFromOld(incompleteIntense: Intense): Promise<Intense> {
        const intense = this.utils.clone(incompleteIntense);
        for (let t = 0; t < intense.tappe.length; t++) {
            const tappa = intense.tappe[t];
            if (tappa.osts) {
                for (let i = tappa.osts.length - 1; i >= 0; i--) {
                    const ost = tappa.osts[i];
                    if (this.utils.isOstTratta(ost)) {
                        const tratto = await this.utils.getOstGeometry(ost);
                        if (!tappa.percorso) {
                            tappa.percorso = { tratti: [] };
                            tappa.percorso.inizio = this.utils.getFirstCoords(tratto);
                            tappa.percorso.ostIniziale = ost;
                        }
                        tappa.percorso.tratti.push({
                            ost: ost,
                            tratto: tratto,
                            ultimoPunto: this.utils.getLastCoords(tratto),
                            parziale: false // nei vecchi gli ost sono sempre completi
                        });

                        tappa.osts.splice(i, 1);
                    }
                }
            } else { tappa.osts = []; }
        }

        return intense;
    }

    public async parseIntese(incompleteIntense: Intense): Promise<Intense> {

        const currentIntense = this.utils.clone(incompleteIntense);
        // funzione per trovare un ost dentro una tappa utilizzate in seguito
        const getOst = (id, tappa: Tappa) => {
            return tappa.osts ? tappa.osts.find(x => x.id === id) : [];
        };

        // ricostruzione dell'oggetto percorso a partire dal geoJson dell'intense
        const percorsi = JSON.parse(currentIntense.geojson.value);
        if (percorsi && percorsi.features) {
            percorsi.features.forEach(feature => {
                // nell'intense esistono già le tappe a cui ogni feature è collegata
                let tappa = currentIntense.tappe[feature.properties.tappaId];
                if (!tappa) {
                    tappa = {};
                }
                if (!tappa.percorso) { // non esiste un percorso, viene creato
                    tappa.percorso = { tratti: [] };
                    // prima feature dovrebbe essere un punto che corrisponde al punto iniziale dell'intense
                    if (feature.geometry.type === 'Point') {
                        tappa.percorso.inizio = feature.geometry.coordinates;
                        tappa.percorso.ostIniziale = getOst(feature.properties.ostid, tappa);
                    }
                }
                // ogni tratto del percorso
                if (feature.geometry.type === 'LineString') {
                    tappa.percorso.tratti.push({
                        tratto: feature.geometry,
                        ost: getOst(feature.properties.ostid, tappa),
                        parziale: feature.properties.parziale,
                        ultimoPunto: {}
                    });
                }
                // punti di fine tratto associati all'ultimo tratto inserito
                if (feature.geometry.type === 'Point' && tappa.percorso.tratti.length > 0) {
                    tappa.percorso.tratti[tappa.percorso.tratti.length - 1].ultimoPunto = feature.geometry.coordinates;
                }
            });
        }

        return currentIntense;
    }

    getUserDetails(userID) {
        const url = environment.userDetailUrl.replace('${userID}', userID);
        return this.http.get(url);

    }

}
