import { Pipe, PipeTransform } from '@angular/core';
import 'leaflet-clicktolerance';
import 'node_modules/leaflet-semicircle';
import 'leaflet.awesome-markers';

export const CURRENT_COLOR = 'orange';
export const NEAR_COLOR = 'red';
export const DISTANT_COLOR = 'green';


@Pipe({ name: 'extractAttribute' })
export class ExtractAttribute implements PipeTransform {

    transform(data: any[], attribute?: string): any[] {
        if (!attribute) {
            return [];
        }
        const temp = [];
        for (let i = 0; i < data.length; i++) {
            const item = data[i];
            if (!!item[attribute]) {
                const obj = {};
                obj[attribute] = item[attribute];
                temp.push(obj);
            }
        }
        return temp;
    }
}

