import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ModalService } from 'src/app/services/modal.service';
// import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Store } from '@ngrx/store';
import { RemovePercorsoTratto, PatchCurrentIntense, SaveIntense, CreateTappa, RemoveTappa, SelectTappa, RemoveOst, HighlightElement, updateIntense, SetType } from 'src/app/logic/search-ost';
import { Subject } from 'rxjs';
import { first, skip } from 'rxjs/operators';
import { Router } from '@angular/router';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DisplayModal, FeatureNotReady } from 'src/app/logic/common';
import { ValidateIntensePipe } from 'src/app/pipes';
import { EditIntenseService } from 'src/app/views/edit-intense/edit-intense.service';
import { SearchOstSelectors } from 'src/app/logic/search-ost/search-ost.selectors';
import { Intense, Tappa, Ost } from 'src/app/model/model.interfaces';
import { UtilsService } from 'src/app/services/utils.service';
import { LayerMakerService } from 'src/app/services/LayerMaker.service';
import { config } from 'src/environments/config/config';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/api.service';
import { AsrAuthGuard } from 'src/app/services/auth.service';
@Component({
    selector: 'app-right-sidebar,[app-right-sidebar]',
    templateUrl: './right-sidebar.component.html'
})
export class RightSideBarComponent implements OnInit, OnDestroy {
    currentIntense: Intense = null;
    currentItem;
    tappe;
    showDetails;
    showFilters;
    description: FormControl;
    destroy$: Subject<boolean> = new Subject<boolean>();
    quillModules = config.QUILL_MODULES;
    languages = config.LANGUAGES;
    translations = [];
    intenseTranslations = [];
    public currentLang = 'it';
    isAdmin = false;

    constructor(
        private modalService: ModalService,
        private searchOstSelectors: SearchOstSelectors,
        private editService: EditIntenseService,
        private utils: UtilsService,
        private router: Router,
        private store: Store<any>,
        private layerMaker: LayerMakerService,
        private translate: TranslateService,
        public apiservice: ApiService,
        private auth: AsrAuthGuard) {
    }

    ngOnInit(): void {
        this.searchOstSelectors.getCurrentIntense$().pipe(takeUntil(this.destroy$), distinctUntilChanged(this.utils.compareGeneralObj)).subscribe(
            value => {
                this.currentItem = value, this.handleTappe(this.currentItem).then(taps => { this.tappe = taps; });
            });
        this.description = new FormControl(this.currentItem && this.currentItem.descrizione);
        this.editService.showDetails$().pipe(takeUntil(this.destroy$)).subscribe(value => this.showDetails = value);
        this.editService.showFilters$().pipe(takeUntil(this.destroy$)).subscribe(value => this.showFilters = value);
        this.description.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(400)).subscribe((data) => {
            this.store.dispatch(new PatchCurrentIntense({ descrizione: data }));
        });
        this.isAdmin = this.auth.isUserValidator();

        this.searchOstSelectors.getCurrentIntenseTranslations$().pipe(takeUntil(this.destroy$)).subscribe(transl => { this.intenseTranslations = transl; });

        // inizializzazione tappa selezionata (su prima tappa)
        this.store.dispatch(new SelectTappa(0));

        this.translate.get([
            'common.defaultlinkname',
            'rightsidebar.confirmremovepoi',
            'rightsidebar.confirmremovepath',
            'rightsidebar.pathmissing'
        ]).subscribe(res => {
            this.translations = res;
        });

        this.currentLang = 'it'; // TODO get currentLang
    }

    async handleTappe(intense: Intense) {
        this.currentIntense = intense;

        const newTappe = [];
        if (!intense || !intense.tappe) {
            return newTappe;
        }

        for (const tappa of intense.tappe) {
            if (tappa.percorso && tappa.percorso.tratti) {
                const osts = this.utils.clone(tappa.osts);
                const tratti = tappa.percorso.tratti;
                const newTappa = { tappa: {}, tratti: [], name: tappa.name };
                for (let i = 0; i < tratti.length; i++) {
                    const newTratto = this.utils.clone(tratti[i]);
                    newTratto.poi = await this.getPoiOfTratto(osts, newTratto);
                    newTappa.tratti.push(newTratto);
                }
                newTappe.push(newTappa);
            }
        }
        return newTappe;
    }

    private async getPoiOfTratto(osts: Ost[], tratto) {
        const pois = [];
        if (osts && osts.length) {
            for (let i = osts.length - 1; i >= 0; i--) {
                if (!this.utils.isOstTratta(osts[i])) {
                    if (await this.utils.isNearTratto(tratto, osts[i])) {
                        pois.push(osts[i]);
                        osts.splice(i, 1);
                    }
                }
            }
        }
        return pois;
    }

    public trattoName(tratto) {
        if (tratto.ost && tratto.ost.name) {
            return tratto.ost.name;
        } else {
            return this.translations['common.defaultlinkname'];
        }
    }

    updateTappaSelected(idx) {
        if (!this.currentIntense) { return; }
        if (idx === -1 || idx == null) {
            this.currentIntense.currentTappa = this.tappe.length - 1;
        } else {
            this.currentIntense.currentTappa = idx;
        }
    }

    ostIcon(poi) {
        return 'fa-' + this.layerMaker.getAwesomeIcon(poi);
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    editOst(ost) {
        let modOst = ost;
        // controllo se non esite già una versione modificata dell'ost
        if (this.currentIntense.osts) {
            const found = this.currentIntense.osts.find(o => o.id === ost.id);
            if (found) { modOst = found; }
        }
        this.modalService.openDialog('editOst', modOst);
    }

    editTappaName(tappa, tappaIdx) {
        this.modalService.openDialog('editTappa', { tappa: tappa, tappaIdx: tappaIdx });
    }

    addOst(ost) {
        this.modalService.openDialog('add', null);
    }

    confirm(ost) {
        this.modalService.openDialog('confirm', ost);
    }

    creaTappa(i, trattoIdx) {
        // this.store.dispatch(new FeatureNotReady());
        this.store.dispatch(new CreateTappa({ indexTappa: i, indexTratto: trattoIdx }));
    }

    eliminaTappa(i) {
        this.store.dispatch(new RemoveTappa({ indexTappa: i }));
    }

    // dropTappa(event: CdkDragDrop<string[]>) {
    //     if (event.previousContainer === event.container) {
    //         moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    //     } else {
    //         transferArrayItem(event.previousContainer.data,
    //             event.container.data,
    //             event.previousIndex,
    //             event.currentIndex);
    //     }
    // }

    removeOst(ost, tappaIdx) {
        const data = {
            message: this.translations['rightsidebar.confirmremovepoi'],
            confirmAction: new RemoveOst({ ost: ost, indexTappa: tappaIdx })
        };
        this.store.dispatch(new DisplayModal({ type: 'delete', data: data }));
    }

    removeTratto(index, tappaIdx) {
        const data = {
            message: this.translations['rightsidebar.confirmremovepath'],
            confirmAction: new RemovePercorsoTratto({ indexTratto: index, indexTappa: tappaIdx })
        };
        this.store.dispatch(new DisplayModal({ type: 'delete', data: data }));
    }

    save() {
        const validator = new ValidateIntensePipe();
        if (validator.transform(this.currentItem)) {
            this.store.dispatch(new SaveIntense(this.currentItem));
        } else {
            this.store.dispatch(new DisplayModal(
                {
                    type: 'confirm',
                    data: { message: this.translations['rightsidebar.pathmissing'] }
                }
            )
            );
        }
    }


    editTitle() {
        this.store.dispatch(new PatchCurrentIntense({ descrizione: this.description.value }));
        this.router.navigate(['new']);
    }

    isTappaSelected(tappaIdx) {
        if (!this.currentIntense) { return false; }
        return this.currentIntense.currentTappa === tappaIdx;
    }

    selectTappa(tappaIdx) {
        // this.selectedTappa = tappa;
        this.store.dispatch(new SelectTappa(tappaIdx));
    }

    aggiungi() {
        this.searchOstSelectors.getType$()
            .pipe(first()).subscribe(type => {
                this.store.dispatch(new SetType('ost_attrattore'));
                this.searchOstSelectors.getResults$()
                    // .pipe(skip(1), first()   => salta il primo risultato (vecchio) e prende quello dato dalla setType
                    .pipe(skip(1), first()).subscribe(allOsts => {
                        // filtrare solo ost che possono essere aggiunti
                        const osts = [];
                        const proms = [];
                        allOsts.forEach(o => {
                            if (!this.utils.isOstTratta(o)) {
                                const prom = this.utils.getOstGeometry(o);
                                prom.then(geom => {
                                    if (this.utils.isNearPercorso(geom, this.currentIntense.tappe[this.currentIntense.currentTappa].percorso)) {
                                        osts.push(o);
                                    }
                                });
                                proms.push(prom);
                            }
                        });
                        Promise.all(proms).then(() => {
                            this.store.dispatch(new SetType(type)); // rimposta tipo di filtro
                            this.store.dispatch(new DisplayModal(
                                { type: 'add', data: { osts: osts } }
                            )
                            );
                        });
                    });
            });

    }

    tipoAttrattore(ost: Ost) {
        switch (ost.type) {
            case 'ost_attrattore': return 'attrattore';
            case 'ost_servizio': return 'servizio';
            case 'ost_waypoint': return 'sosta';
            default: return '';
        }
    }

    highlightElement($event, obj) {
        if ($event) { $event.stopPropagation(); }
        this.store.dispatch(new HighlightElement(obj));
    }

    showPhotos() {
        this.modalService.openDialog('editIntenseFile', { type: 'image', intense: this.currentIntense });
    }

    showFiles() {
        this.modalService.openDialog('editIntenseFile', { type: 'file', intense: this.currentIntense });
    }

    translateModal(lang) {
        this.modalService.openDialog('translate', { intense: this.currentIntense, lang: lang });
    }

    isDanger() {
        return this.utils.isIntenseInterrupted(this.currentIntense);
    }

    statoLingua(lang) {
        if (this.intenseTranslations && this.intenseTranslations[lang]) {
            return !this.intenseTranslations[lang].stato || this.intenseTranslations[lang].stato === this.apiservice.STATO_DRAFT ? this.apiservice.CLASSE_TRADUZIONE_VUOTO :
                this.intenseTranslations[lang].stato === this.apiservice.STATO_PUBBLICATA ? this.apiservice.CLASSE_TRADUZIONE_COMPLETO :
                    this.apiservice.CLASSE_TRADUZIONE_NONCOMPLETO;
        }
        return this.apiservice.CLASSE_TRADUZIONE_VUOTO; // stato della traduzione
    }

    attenzioneLingua(lang) {
        if (this.intenseTranslations && this.intenseTranslations[lang]) {
            // return this.currentIntense.changed > this.intenseTranslations[lang].changed;
            return this.intenseTranslations[lang].outdated;
        }
        return false; // TODO lingua non aggiornata
    }

    changeStatus($event) {
        this.store.dispatch(new PatchCurrentIntense({ stato: $event.currentTarget.value }));
    }

    difficolta(currentItem) {
        const res = [];
        if (currentItem && currentItem.difficolta) {
            currentItem.difficolta.forEach(diff => {
                if (diff.name && !res.includes(diff.name)) {
                    res.push(diff.name);
                }
            });
        }
        this.utils.getOstsList(currentItem).forEach(ost => {
            if (ost.gradoDifficolta) {
                ost.gradoDifficolta.forEach(diff => {
                    if (diff.name && !res.includes(diff.name)) {
                        res.push(diff.name);
                    }
                });
            }
        });
        return res;
    }

    fruizione(currentItem) {
        const res = [];
        if (currentItem && currentItem.fruizione) {
            currentItem.fruizione.forEach(fru => {
                if (fru.name && !res.includes(fru.name)) {
                    res.push(fru.name);
                }
            });
        }
        this.utils.getOstsList(currentItem).forEach(ost => {
            if (ost.fruizione) {
                ost.fruizione.forEach(fru => {
                    if (fru.name && !res.includes(fru.name)) {
                        res.push(fru.name);
                    }
                });
            }
        });
        return res;
    }

}
