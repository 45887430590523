import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { EditIntenseService } from 'src/app/views/edit-intense/edit-intense.service';
import { takeUntil, take } from 'rxjs/operators';
import { SearchOstSelectors } from 'src/app/logic/search-ost/search-ost.selectors';
import { Store } from '@ngrx/store';
import { SetFilter, SetType } from 'src/app/logic/search-ost';
import { FormControl } from '@angular/forms';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
    selector: 'app-left-sidebar, [app-left-sidebar]',
    templateUrl: './left-sidebar.component.html'
})
export class LeftSideBarComponent implements OnInit, OnDestroy {

    showDetails;
    showFilters;
    activeFilters;
    filters$;
    destroy$: Subject<boolean> = new Subject<boolean>();
    radioControl: FormControl;
    results$;
    constructor(
        private store: Store<any>,
        private searchOstSelectors: SearchOstSelectors,
        private editService: EditIntenseService,
        private utils: UtilsService
    ) {
    }

    ngOnInit(): void {
        this.store.dispatch(new SetType('ost_sentiero_percorso')); // default
        this.editService.showDetails$().pipe(takeUntil(this.destroy$)).subscribe(value => {
            this.showDetails = value;
        });
        this.editService.showFilters$().pipe(takeUntil(this.destroy$)).subscribe(value => {
            this.showFilters = value;
        });
        this.searchOstSelectors.getActiveFilters$().pipe(takeUntil(this.destroy$)).subscribe((data) => {
            this.activeFilters = this.utils.clone(data);
        });
        this.searchOstSelectors.getType$().pipe(take(1)).subscribe(val => {
            if (val === null) {
                // this.radioControl = new FormControl('all');
                this.radioControl = new FormControl('ost_sentiero_percorso');
            } else {
                this.radioControl = new FormControl(val);
            }
            this.radioControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(data => {
                if (data === 'all') {
                    this.store.dispatch(new SetType());
                } else {
                    this.store.dispatch(new SetType(data));
                }
            });
        });
        this.results$ = this.searchOstSelectors.getResults$();
        this.filters$ = this.searchOstSelectors.getFilters$();
    }

    toggleFilters() {
        this.editService.toggleFilters$();
    }

    setFilter(filter, option) {
        const index = this.filterIndex(filter);
        if (index === -1) {
            const newFilter = { name: filter, values: [option] };
            this.activeFilters.push(newFilter);
        } else {
            const currentFilter = this.activeFilters[index];
            const optionIndex = this.optionIndex(currentFilter, option);
            if (optionIndex === -1) {
                if (currentFilter.values) {
                } else {
                    currentFilter.values = [];
                }
                currentFilter.values.push(option);
            } else {
                currentFilter.values.splice(optionIndex, 1);
                if (currentFilter.values.length === 0) {
                    this.activeFilters.splice(index, 1);
                }
            }
        }
        this.store.dispatch(new SetFilter(this.activeFilters));
    }

    isFilterSelected(filter, value) {
        let ret = false;
        const filterContained = this.activeFilters.find(f => f.name === filter);
        if (filterContained) {
            ret = !value || filterContained.values.includes(value);
        }
        // console.log('------------> filter, value', this.activeFilters,filter, value, ret);
        return ret;
    }

    removeFilter(filter, value) {
        this.setFilter(filter.name, value);
    }

    
    clearFilter() {
        this.store.dispatch(new SetFilter([]));
    }

    private filterIndex(filterName: string) {
        let index = -1;
        for (let i = 0; i < this.activeFilters.length; i++) {
            const e = this.activeFilters[i];
            if (e.name === filterName) {
                index = i;
                break;
            }
        }
        return index;
    }

    private optionIndex(filter, value) {
        if (!filter.values) {
            return -1;
        }
        return filter.values.indexOf(value);
    }

    isChecked(filter, option) {
        const filterIndex = this.filterIndex(filter.name);
        if (filterIndex === -1) {
            return false;
        }
        const currentFilter = this.activeFilters[filterIndex];
        const optionIndex = this.optionIndex(currentFilter, option.key);
        return optionIndex > -1;
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    editIntense() {
    }
}
