import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { UtilsService } from './utils.service';
const AUTHTOKEN_KEY = 'authToken';
const USER_ID_KEY = 'userId';

@Injectable({ providedIn: 'root' })
export class AsrAuthGuard implements CanActivate {

    private authToken = null;
    private isOauth = true;
    private userId;
    private userData: any;

    constructor(
        private router: Router,
        private oauthService: OAuthService,
        private utils: UtilsService
    ) {

        this.utils.getLocal(AUTHTOKEN_KEY).then(val => {
            if (val) {
                this.authToken = val;
            }
        });

        this.utils.getLocal(USER_ID_KEY).then(val => {
            if (val) {
                this.userId = val;
            }
        });
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        const valid = this.oauthService.hasValidAccessToken() || !!this.authToken;
        if (!valid) {
            this.router.navigate(['login']);
        }
        return valid;
    }

    login(user, pwd) {
        return this.oauthService.fetchTokenUsingPasswordFlow(user, pwd).then(resp => {
            // invalidare sistema token
            this.setToken(null, resp['user_id']);
            this.isOauth = true;
            // this.userId = resp['user_id'];
            return resp;
        });
    }

    logout() {
        if (this.isOauth) {
            this.oauthService.logOut();
        }

        this.setToken(null, null);
        this.isOauth = false;
        this.setUserData(null);
    }

    isLogged() {
        return this.oauthService.hasValidAccessToken() || !!this.authToken;
    }

    getProfileImg() {
        return null; // get user image
    }

    setToken(tok, userId) {
        if (tok) {
            // TODO invalidare login oauth
            this.isOauth = false;
        }
        this.userId = userId;
        this.authToken = tok;
        this.utils.setLocal(AUTHTOKEN_KEY, tok);
        this.utils.setLocal(USER_ID_KEY, userId);
    }

    resetToken() {
        this.setToken(null, null);
    }

    getToken() {
        return this.isOauth ? null : this.authToken;
    }

    public async getUserId() {
        if (this.userId) {
            return this.userId;
        } else {
            return this.utils.getLocal(USER_ID_KEY);
        }
    }

    public setUserData(userData) {
        // console.log('++++++++++++> setUserData -> userData', userData);
        this.userData = userData;
    }

    public isUserDataSet() {
        return !!this.userData;
    }

    public isUserValidator() {
        // console.log('-----------: AsrAuthGuard -> isUserValidator -> this.userData', this.userData);
        if (!this.userData || !this.userData.roles) { return null; }
        let ret = false;

        this.userData.roles.forEach(element => {
            ret = ret || element.target_id === 'ost_editor' || element.target_id === 'administrator';
        });

        // console.log('------------> isUserValidator -> ret', ret);
        return ret;
    }

}
