import { Component, OnInit, Input, OnDestroy, AfterViewInit } from '@angular/core';
import * as L from 'leaflet';
import { latLng, tileLayer, Marker } from 'leaflet';
import { Observable } from 'rxjs';
import { UtilsService } from 'src/app/services/utils.service';
@Component({
    selector: 'app-preview-map, [app-preview-map]',
    templateUrl: './map-preview.component.html'
})
export class MapPreviewComponent implements OnInit, AfterViewInit, OnDestroy {
    public divId = '';
    private iconDefault = L.icon({
        iconSize: [25, 41],
        iconAnchor: [13, 0],
        iconUrl: 'leaflet/marker-icon.png',
        shadowUrl: 'leaflet/marker-shadow.png'
    });
    OSM = tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18,
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    });
    options = {
        layers: [this.OSM],
        zoom: 7,
        zoomControl: false,
        attributionControl: false,
        zoomAnimation: false,
        center: latLng(40.097262, 9.140745)
    };
    map: L.Map;
    private feature;
    @Input() geometry: Observable<any>;
    @Input() id;
    constructor(private utils: UtilsService) { }

    ngOnInit(): void {
        Marker.prototype.options.icon = this.iconDefault;
        this.divId = 'preview-map-' + this.id ;
    }

    ngAfterViewInit(): void {
        if (!this.feature) {
            const id = this.divId;
            if (!this.map) {
                this.map = L.map(id, this.options);
            }
            this.addGeometry(this.geometry);
        }
    }

    private async addGeometry(id) {
        const geo = await this.utils.getGenericGeometry(id);
        if (geo) {
            this.feature = L.geoJSON(geo).addTo(this.map);
            try {
                this.map.fitBounds(this.feature.getBounds());
            } catch (error) {
                console.log(id);
            }
            this.map.dragging.disable();
            this.map.touchZoom.disable();
            this.map.doubleClickZoom.disable();
            this.map.scrollWheelZoom.disable();
            this.map.boxZoom.disable();
            this.map.keyboard.disable();
            if (this.map.tap) {
                this.map.tap.disable();
            }
        }
    }

    ngOnDestroy(): void {
        if (this.map) {
            this.map.off();
            this.map.remove();
        }
    }
}

