import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
    SearchIntenseActionTypes,
    SearchIntenseSuccess,
    DeleteIntenseSuccess,
    DeleteIntenseFail,
    SearchIntenseFail
} from './search-intense.actions';
import { switchMap, map, withLatestFrom, tap, debounceTime, catchError, } from 'rxjs/operators';
import { of } from 'rxjs';
import { SearchIntenseSelectors } from './search-intense.selectors';
import { SearchIntenseRequests } from './search-intense.request';

@Injectable({
    providedIn: 'root'
})
export class SearchIntenseEffects {

    constructor(
        private actions$: Actions,
        private searchSelectors: SearchIntenseSelectors,
        private searchRequests: SearchIntenseRequests,
    ) { }

    @Effect() doSearch$ = this.actions$.pipe(
        ofType(SearchIntenseActionTypes.SearchIntense,
            SearchIntenseActionTypes.SetBoundingBox,
            SearchIntenseActionTypes.SetFilter,
            SearchIntenseActionTypes.SetQuery,
            SearchIntenseActionTypes.ClearFilters,
            SearchIntenseActionTypes.DeleteIntenseSuccess,
            SearchIntenseActionTypes.SetStatus,
            SearchIntenseActionTypes.SetUid),
        map(action => action['payload']),
        debounceTime(400),
        withLatestFrom(this.searchSelectors.getSearchRequest$()),
        switchMap(([_, request]) => {
            // console.log('-----------: SearchIntenseEffects -> request', request);
            return this.searchRequests.doSearch$(request).pipe(
                map(data => new SearchIntenseSuccess(data)),
                catchError(error => of(new SearchIntenseFail(error)))
            );
        }),
    );

    @Effect() deleteIntense$ = this.actions$.pipe(
        ofType(SearchIntenseActionTypes.DeleteIntense),
        map(action => action['payload']),
        switchMap(action =>
            this.searchRequests.doDelete$(action).pipe(
                map(data => new DeleteIntenseSuccess(data)),
                catchError(error => { console.log(error); return of(new DeleteIntenseFail(error)); })
            )),
    );
}
