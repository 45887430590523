import { Injectable } from '@angular/core';
import { Ost, PuntoPercorsoSuggerito, Percorso, TrattoPercorso, Geometry } from 'src/app/model/model.interfaces';
import * as turf from '@turf/helpers';
import * as line_slice from '@turf/line-slice';
import * as line_intersect from '@turf/line-intersect';
import { UtilsService } from './utils.service';
import { config } from 'src/environments/config/config';


@Injectable({ providedIn: 'root' })
export class PercorsoService {


  constructor(private utils: UtilsService) { }

  public async getNextPoints(
    puntoIniziale: PuntoPercorsoSuggerito,
    osts: Ost[], iniziale?: boolean
  ): Promise<PuntoPercorsoSuggerito[]> {

    // lista ost collegati a quel punto
    const ostInPoint = await this.ostInQuelPunto(osts, puntoIniziale, iniziale);

    const nextPoints: PuntoPercorsoSuggerito[] = [];

    // check se è estremo dell'ost
    const isEstremo = true; // iniziale || (puntoIniziale.ost && this.isEstremo(ostlinea, puntoIniziale.punto));

    for (const ost of osts) {
      if (this.utils.isOstTratta(ost)) {

        // if (puntoIniziale.ostProvenienza && ost.id !== puntoIniziale.ostProvenienza.id) {

        // ricerca delle intersezioni degli ost con gli ost che passano per il punto
        (await this.findIncroci(ost, ostInPoint)).forEach(x => nextPoints.push(x));

        // se il punto è un estremo del suo ost
        // si aggiungono anche i punti di partenza di altri ost che sono distanti meno di una soglia (CONST)
        if (isEstremo && !iniziale) {
          (await this.addStartingPointsOtherOst(ost, puntoIniziale)).forEach(x => nextPoints.push(x));
        }
        // }
      }
    }

    for (const searchOst of ostInPoint) {
      // aggiunta degli estremi degli ost che passano per il punto (e non sono il punto)
      (await this.addStartingPointsSameOst(searchOst, puntoIniziale)).forEach(x => nextPoints.push(x));
    }

    this.puliziaDoppioni(nextPoints, puntoIniziale);

    console.debug('NEXTPOINTS', nextPoints);
    return nextPoints;
  }

  private puliziaDoppioni(punti: PuntoPercorsoSuggerito[], puntoIniziale: PuntoPercorsoSuggerito) {
    punti.forEach(punto => {
      if (!punto['toRemove']) {
        // punto iniziale
        if (puntoIniziale && puntoIniziale.punto && this.utils.distance(punto.punto, puntoIniziale.punto) === 0) {
          punto['toRemove'] = true;
        } else {
          // stesso punto
          punti.forEach(p => {
            // if (this.utils.isSamePoint(p.punto, punto.punto) && !this.utils.compareGeneralObj(punto, p) && !p['toRemove'] && !punto['toRemove']) {
            if (this.utils.distance(p.punto, punto.punto) < config.DISTANZA_FILTRO_NODI_KM && !this.utils.compareGeneralObj(punto, p) && !p['toRemove'] && !punto['toRemove']) {

              // punto di inizio lontano sovrapposto a punto di inizio connesso
              if (!p.ostProvenienza && punto.ostProvenienza) {
                p['toRemove'] = true;
                // if (!this.utils.compareGeneralObj(punto.ostConnessi, p.ostConnessi)) {
                //   punto.ostConnessi = punto.ostConnessi.concat(p.ostConnessi);
                // }
              } else {
                // incroci sovrapposti
                if (
                  p.ostConnessi && p.ostConnessi.length // è un incrocio
                  && punto.ostConnessi && punto.ostConnessi.length // è un incrocio
                  // && this.utils.compareGeneralObj(p.ostProvenienza, punto.ostProvenienza)
                ) {
                  p['toRemove'] = true;
                  for (const ost of p.ostConnessi) {// si aggiungono le connessioni, evitando ripetizioni di ost
                    if (punto.ostConnessi.find(o => o.id === ost.id)) {
                      punto.ostConnessi.push(ost);
                    }
                  }
                } else {
                  // punto di arrivo sopra punto di incrocio
                  if (
                    p.ostConnessi && p.ostConnessi.length // incrocio
                    && (punto.ostProvenienza && (!punto.ostConnessi || punto.ostConnessi.length < 1)) // punto di arrivo/partenza
                  ) {
                    p['toRemove'] = true;
                  }
                }
              }
            }
          });
        }

        // incroci vicini ??
      }
    });

    // rimozione effettiva
    for (let i = punti.length - 1; i >= 0; i--) {
      if (punti[i]['toRemove']) {
        punti.splice(i, 1);
      }
    }
  }

  private async addStartingPointsSameOst(ost, punto): Promise<PuntoPercorsoSuggerito[]> {
    const nextPoints: PuntoPercorsoSuggerito[] = [];
    if (!ost) { return nextPoints; }
    const lineOst = await this.utils.getOstGeometry(ost);
    const begin = this.utils.getFirstCoords(lineOst);
    if (!this.utils.isSamePoint(begin, punto.punto)) {
      nextPoints.push({ punto: begin, ostProvenienza: ost, ostConnessi: [] });
      // console.log('-ESTREMO DEL PERCORSO STESSO', begin);
    }
    const end = this.utils.getLastCoords(lineOst);
    if (!this.utils.isSamePoint(end, punto.punto)
      || this.utils.isSamePoint(end, begin) // se è un percorso circolare con partenza/arrivo coincidenti si aggiungono entrambi
    ) {
      nextPoints.push({ punto: end, ostProvenienza: ost, ostConnessi: [] }); // TODO: deveno essere differenti in caso di anello
      // console.log('-ESTREMO DEL PERCORSO STESSO', end);
    }
    return nextPoints;
  }

  private async addStartingPointsOtherOst(ost, punto): Promise<PuntoPercorsoSuggerito[]> {
    const lineOst = await this.utils.getOstGeometry(ost);
    const nextPoints: PuntoPercorsoSuggerito[] = [];
    const dist1 = this.utils.distance(this.utils.getFirstCoords(lineOst), punto.punto);
    if (dist1 < config.DISTANZA_TRA_ESTREMI_PROPOSTA_KM && dist1 !== 0) {
      nextPoints.push({ punto: this.utils.getFirstCoords(lineOst), ostProvenienza: null, ostConnessi: [ost] });
    }
    const dist2 = this.utils.distance(this.utils.getLastCoords(lineOst), punto.punto);
    if (dist2 < config.DISTANZA_TRA_ESTREMI_PROPOSTA_KM && dist2 !== 0) {
      nextPoints.push({ punto: this.utils.getLastCoords(lineOst), ostProvenienza: null, ostConnessi: [ost] });
    }
    return nextPoints;
  }


  private async ostInQuelPunto(osts: Ost[], punto: PuntoPercorsoSuggerito, iniziale): Promise<Ost[]> {
    if (punto.ostConnessi && punto.ostConnessi.length) {
      // is conoscono già gli ost collegati a quel punto, si aggiunge l'ost stesso
      return punto.ostConnessi.concat(punto.ostProvenienza);
    } else {
      if (!iniziale || (iniziale && !punto.ostProvenienza)) {
        // si trovano tutti i tratti (escluso quello di provenienza) che passano da quel punto
        return await this.ostCalcolatiInQuelPunto(osts, punto);
      } else {
        // punto iniziale, si considera l'ost di cui il punto è la partenza
        return [punto.ostProvenienza];
      }
    }
  }

  private async ostCalcolatiInQuelPunto(osts: Ost[], punto: PuntoPercorsoSuggerito): Promise<Ost[]> {
    const ostInPoint = [];
    for (const ost of osts) {
      if (this.utils.isOstTratta(ost)) { // && (!punto.ostProvenienza || ost.id !== punto.ostProvenienza.id)) {
        // è un tratto e non è quello dell'ost del punto

        // recupero della geometria
        const linea = await this.utils.getOstGeometry(ost);

        if (this.utils.isOnLine(punto.punto, linea)) {
          ostInPoint.push(ost);
        }
      }
    }
    return ostInPoint;
  }

  private async findIncroci(ost, osts): Promise<PuntoPercorsoSuggerito[]> {
    const linea = await this.utils.getOstGeometry(ost);
    const res = [];
    for (const searchOst of osts) {
      // non si considerano le intersezioni con lo stesso ost
      if (ost && ost.id && searchOst && searchOst.id && ost.id !== searchOst.id) {
        const lineOstOfPoint = await this.utils.getOstGeometry(searchOst);

        // e raccolta delle intersezioni di tutti quei tratti (compresi gli estremi di quei tratti)
        const points = this.intersezione(linea, lineOstOfPoint);
        for (const p of points.features) {
          if (p) {
            // console.log('-INTERSEZIONE', p);
            res.push({ punto: this.utils.featureToArray(p), ostProvenienza: searchOst, ostConnessi: [ost] });
          }
        }
      }
    }
    return res;
  }

  public intersezione(linea1, linea2) {
    // ricerca delle intersezioni fra due tratti con filtraggio
    // ricerca di tutti i punti di intersezione
    let res = turf.featureCollection<turf.Point>([]);
    if (linea1 && linea2) {
      const allIntersect = line_intersect.default(linea1, linea2);
      // se sono 2 o meno si prendo tutti
      if (allIntersect.features.length < 2) { res = allIntersect; } else {
        res.features.push(allIntersect.features[0]);
        const last = allIntersect.features[allIntersect.features.length - 1];
        let precedente = allIntersect.features[0];
        // si esaminano tutti i punti e si scegono solo quelli che distano più della distanza minima dal precendete
        allIntersect.features.forEach(feature => {
          if (this.utils.distance(precedente, feature) > config.DISTANZA_FILTRO_INCROCI_KM) { res.features.push(feature); }
          precedente = feature;
        });
        // si inserisce comunque aggiungere l'ultimo
        if (res.features[res.features.length - 1] !== last) { res.features.push(last); }
      }
      // punti di vicinanza:
      this.intersezionePerEstremi(linea1, linea2).features.forEach(feat => res.features.push(feat));
    }


    return res;
  }

  private intersezionePerEstremi(linea1, linea2): turf.FeatureCollection<turf.Point> {
    const res = turf.featureCollection<turf.Point>([]);
    if (this.utils.calculateDistanceFromTratto(linea2, this.utils.getFirstCoords(linea1)) < config.DISTANZA_LIMITE_CONCATENABILI_KM) { res.features.push(this.utils.getFirstCoords(linea1)); }
    if (this.utils.calculateDistanceFromTratto(linea2, this.utils.getLastCoords(linea1)) < config.DISTANZA_LIMITE_CONCATENABILI_KM) { res.features.push(this.utils.getLastCoords(linea1)); }
    if (this.utils.calculateDistanceFromTratto(linea1, this.utils.getFirstCoords(linea2)) < config.DISTANZA_LIMITE_CONCATENABILI_KM) { res.features.push(this.utils.getFirstCoords(linea2)); }
    if (this.utils.calculateDistanceFromTratto(linea1, this.utils.getLastCoords(linea2)) < config.DISTANZA_LIMITE_CONCATENABILI_KM) { res.features.push(this.utils.getLastCoords(linea2)); }
    return res;
  }

  public removeOstdaPercorso(ost: Ost, percorso: Percorso): Percorso {
    // rimuove ost e modifica il percorso eliminando tutte le tratta che dipendono da quell'ost e le successive
    const idx = percorso.tratti.findIndex(t => t.ost && t.ost.id === ost.id);
    percorso.tratti.splice(idx);
    percorso.ultimoPunto = null;
    return percorso;
  }

  // inserimento nel percorso di un nuovo punto
  public async PercorsoWithNewPoint(puntoscelto: PuntoPercorsoSuggerito, percorsoAttuale: Percorso): Promise<Percorso> {
    const percorsofinora: Percorso = this.utils.clone(percorsoAttuale);

    // puntoscelto comprende l'ost (a meno di non essere un collegamento per vicinanza)
    // creazione del percorso:
    let tratto: Geometry = null;
    let isParziale = false;
    if (!puntoscelto.ostProvenienza) { // ost==null =>linea diretta (tra due punti di partenza)
      const ultimopunto = this.ultimoPuntoPercorso(percorsofinora);
      const startP = this.utils.getCoords(ultimopunto.punto);

      tratto = turf.lineString([
        startP,
        this.utils.getCoords(puntoscelto.punto)]);
    } else {
      const ultimopunto = this.ultimoPuntoPercorso(percorsofinora);
      const partenza = ultimopunto.punto;
      const arrivo = puntoscelto.punto;
      const ost = puntoscelto.ostProvenienza;
      isParziale = !(await this.utils.isEstremiOst(ost, partenza, arrivo));
      tratto = await this.getSegmento(ost, partenza, arrivo, isParziale);
    }
    percorsofinora.ultimoPunto = puntoscelto;

    percorsofinora.tratti.push({ ost: puntoscelto.ostProvenienza, tratto: tratto, ultimoPunto: puntoscelto.punto, parziale: isParziale });
    return percorsofinora;
  }

  public ultimoPuntoPercorso(percorsofinora: Percorso): PuntoPercorsoSuggerito {
    if (!percorsofinora) { return null; }
    if (percorsofinora.ultimoPunto) {
      return percorsofinora.ultimoPunto;
    }
    if (percorsofinora.tratti && percorsofinora.tratti.length) {
      // ultimo tratto
      const lastTratto = percorsofinora.tratti[percorsofinora.tratti.length - 1];
      console.log('-----------: PercorsoService -> constructor -> lastTratto', lastTratto);
      // ultimo punto del tratto
      // const lastPoint = this.getLastCoords(lastTratto.tratto);
      const lastPoint = lastTratto.ultimoPunto;
      if (lastPoint) {
        const point = turf.point([lastPoint[0], lastPoint[1]]);
        return { punto: point, ostProvenienza: lastTratto.ost };
      }  else {
        console.log('ERRORE');
        return { punto: percorsofinora.inizio, ostProvenienza: lastTratto.ost };
      }
    } else {
      // console.log('ultimoPuntoPercorso (iniziale)', percorsofinora);
      return { punto: percorsofinora.inizio, ostProvenienza: percorsofinora.ostIniziale };
    }
  }

  public removePuntoPercorso(percorso: Percorso): Percorso {
    // eliminazione ultimo punto percorso e ricalcolo dei punti successivi
    if (percorso.tratti.length) {
      percorso.tratti.pop();
    } else {
      percorso.inizio = null;
    }
    percorso.ultimoPunto = null;
    return percorso;
  }

  public removeIndexPercorso(percorso: Percorso, index: number): Percorso {
    // eliminazione dei punti a partire da index
    if (percorso.tratti.length >= index) {
      percorso.tratti.splice(index);
    } else {
      percorso.inizio = null;
    }
    percorso.ultimoPunto = null;
    return percorso;
  }

  public concatenabili(percorso1: Percorso, percorso2: Percorso): boolean {
    // controllo se la fine del percorso 1 è sufficientemente vicina all'inizio del percorso 2
    const fine1 = this.ultimoPuntoPercorso(percorso1).punto;
    const inizio2 = percorso2.inizio;
    const distanza = this.utils.distance(fine1, inizio2);
    return distanza < config.DISTANZA_LIMITE_CONCATENABILI_KM;
  }

  public slicePercorso(percorso: Percorso, index: number): Percorso {
    const newPercorso: Percorso = { tratti: [] };
    const lastTratto = percorso.tratti[index];

    newPercorso.inizio = lastTratto.ultimoPunto;
    newPercorso.ostIniziale = lastTratto.ost;
    newPercorso.ultimoPunto = percorso.ultimoPunto;

    // percorso.ultimoPunto = null; // lastTratto.ultimoPunto;

    newPercorso.tratti = percorso.tratti.slice(index + 1);

    this.removeIndexPercorso(percorso, index + 1);
    return newPercorso;
  }

  public concat(percorso1: Percorso, percorso2: Percorso): Percorso {
    const newPercorso: Percorso = this.utils.clone(percorso1);
    for (const t2 of percorso2.tratti) {
      newPercorso.tratti.push(this.utils.clone(t2));
    }
    newPercorso.ultimoPunto = percorso2.ultimoPunto;
    return newPercorso;
  }

  public getOstsTratti(percorso: Percorso) {
    const res = [];
    percorso.tratti.forEach(tratto => {
      if (tratto.ost) {
        res.push(tratto.ost);
      }
    });
    return res;
  }

  public async getSegmento(ost: Ost, startPoint: Geometry, endPoint: Geometry, isPartial: boolean): Promise<Geometry> {
    const linearetta = turf.lineString([
      this.utils.featureToArray(startPoint),
      this.utils.featureToArray(endPoint)]);

    if (!ost) { return linearetta; }



    const line = await this.utils.getOstGeometry(ost);
    if (!isPartial || (!!ost && this.utils.isSamePoint(endPoint, startPoint))) { return line; }

    //////////// patch BRUTTA per non fare errore con MultiLineString, non dovrebbero esserci MultiLineString ///////////
    if (line.type === 'MultiLineString') {
      return linearetta;
      // return line;
    }
    //////////////////////////////////////////////////////////////////////////////////

    return line_slice.default(this.utils.getCoords(startPoint), this.utils.getCoords(endPoint), line);
  }

}
