import { Component, OnInit, OnDestroy } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet.awesome-markers';
import 'leaflet-clicktolerance';
import { latLng, tileLayer, Marker } from 'leaflet';
import { Store } from '@ngrx/store';
import { SetBoundingBox, RemovePercorsoTratto } from 'src/app/logic/search-ost';
import { EditIntenseService } from 'src/app/views/edit-intense/edit-intense.service';
import { takeUntil, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Ost, PuntoPercorsoSuggerito, Percorso } from 'src/app/model/model.interfaces';
import { SearchOstSelectors } from 'src/app/logic/search-ost/search-ost.selectors';
import { NgElement, WithProperties } from '@angular/elements';
import { AddTrattoPopupComponent, AddPoiPopupComponent } from '../popups';
import { UtilsService } from 'src/app/services/utils.service';
import { LayerMakerService } from 'src/app/services/LayerMaker.service';
import { PercorsoService } from 'src/app/services/percorso.service';
import { ResizedEvent } from 'angular-resize-event';
import { config } from 'src/environments/config/config';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppDataSelectors } from 'src/app/logic/app-data/app-data.selectors';
import { SnackbarComponent } from '../modals/snackbar/snackbar.component';
@Component({
    selector: 'app-edit-map, [app-edit-map]',
    templateUrl: './map-edit.component.html'
})
export class MapEditComponent implements OnInit, OnDestroy {

    private iconDefault = L.icon({
        iconSize: [25, 41],
        iconAnchor: [13, 0],
        iconUrl: 'leaflet/marker-icon.png',
        shadowUrl: 'leaflet/marker-shadow.png'
    });
    private startingBounds = [[
        41.31494988250965,
        8.118896484375,
    ],
    [
        38.850938169890064,
        9.7943115234375
    ]
    ];
    OSM = tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 20,
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    });
    SATELLITE = tileLayer.wms('http://213.215.135.196/reflector/open/service?', {
        layers: 'rv1',
        format: 'image/jpeg',
        attribution: '© RealVista1.0 WMS OPEN di e-GEOS SpA - CC BY SA'
    });
    baseLayers = {
        'OSM': this.OSM,
        'Satellite': this.SATELLITE
    };
    options = {
        layers: [this.OSM],
        zoom: 7,
        center: latLng(40.097262, 9.140745)
    };

    private map: L.Map;
    private state: String;
    private resultsLayer;
    private startPointsLayer;
    private nextPointsLayer;
    private percorsoLayer;
    private previewLayer;
    private highlightLayer;
    private resultsOst = [];
    private destroy$: Subject<void> = new Subject<void>();
    private layerMapById = {};
    private currentIntenseLayer = null;
    private currentIntenseTappaLayer = null;
    private currentIntense = null;

    private regionContainerLayer;
    private normalContainerLayer;

    private regionLayers = [];
    private clusterLayers = [];
    private clusters = [];

    constructor(
        private searchOstSelectors: SearchOstSelectors,
        private editService: EditIntenseService,
        private store: Store<any>,
        private utils: UtilsService,
        private layerMaker: LayerMakerService,
        private percorso: PercorsoService,
        private matSnack: MatSnackBar,
        private appDataSelector: AppDataSelectors,
    ) { }

    ngOnInit(): void {
        Marker.prototype.options.icon = this.iconDefault;
        this.map = L.map('edit-map', this.options);
        this.map.fitBounds(this.startingBounds, { maxZoom: 9 });
        L.control.layers(this.baseLayers).addTo(this.map);
        Marker.prototype.options.icon = this.iconDefault;

        this.regionContainerLayer = L.featureGroup().addTo(this.map);
        this.normalContainerLayer = L.featureGroup().addTo(this.map);

        this.previewLayer = L.featureGroup().addTo(this.normalContainerLayer);
        this.highlightLayer = L.featureGroup().addTo(this.normalContainerLayer);
        this.percorsoLayer = L.featureGroup().addTo(this.normalContainerLayer);
        this.resultsLayer = L.featureGroup().addTo(this.normalContainerLayer);
        this.startPointsLayer = L.featureGroup().addTo(this.normalContainerLayer);
        this.nextPointsLayer = L.featureGroup().addTo(this.normalContainerLayer);

        this.map.on('moveend', () => {
            try {
                const bounds = this.map.getBounds();
                const lng1 = bounds._southWest.lng >= -180
                    && bounds._southWest.lng < 180 ? bounds._southWest.lng : bounds._southWest.lng > 0 ? 180 : -180;
                const lat1 = bounds._northEast.lat >= -90
                    && bounds._northEast.lat < 90 ? bounds._northEast.lat : bounds._northEast.lat > 0 ? 90 : -90;
                const lng2 = bounds._northEast.lng >= -180
                    && bounds._northEast.lng < 180 ? bounds._northEast.lng : bounds._northEast.lng > 0 ? 180 : -180;
                const lat2 = bounds._southWest.lat >= -90
                    && bounds._southWest.lat < 90 ? bounds._southWest.lat : bounds._southWest.lat > 0 ? 90 : -90;
                const coords = [[lng1, lat1], [lng2, lat2]];
                this.store.dispatch(new SetBoundingBox({ 'type': 'envelope', values: coords }));
            } catch (error) {
                console.log(error);
            }
        });
        this.searchOstSelectors.getResultsAndType$().pipe(
            takeUntil(this.destroy$),
            distinctUntilChanged(this.compareResults),
            debounceTime(100)).subscribe(
                val => this.handleResults(val.results, val.update));
        this.searchOstSelectors.getCurrentIntense$().pipe(takeUntil(this.destroy$), distinctUntilChanged(this.utils.compareGeneralObj), debounceTime(100)).subscribe(
            val => this.handleCurrentIntense(val));
        // this.editService.getState$().pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe(
        //     val => {console.log(val),this.map.invalidateSize(true)});

        this.searchOstSelectors.getSelectedOst$().pipe(takeUntil(this.destroy$), distinctUntilChanged(this.utils.compareGeneralObj)).subscribe(
            val => { if (val) { this.openPop(val); } });

        this.searchOstSelectors.getHighlightElement$().pipe(takeUntil(this.destroy$), distinctUntilChanged(this.utils.compareGeneralObj)).subscribe(
            val => { this.highlight(val); });

        // evento visualizzazione dettagli
        this.editService.showDetails$().pipe(takeUntil(this.destroy$)).subscribe(value => this.detailsView(value));

        this.searchOstSelectors.getFacets$().pipe(takeUntil(this.destroy$), distinctUntilChanged(), debounceTime(1000)).subscribe(val => {
            this.clearRegionAndCluster(); this.updateClusters(val);
        });
    }
    private compareResults(prev, current): boolean {
        const prevIds = [];
        const currentIds = [];
        if (!(Array.isArray(prev) && Array.isArray(current))) {
            return false;
        }

        for (let i = 0; i < prev.length; i++) {
            const element = prev[i];
            prevIds.push(element.id);
        }


        for (let i = 0; i < current.length; i++) {
            const element = current[i];
            currentIds.push(element.id);
        }

        if (prevIds.length !== currentIds.length) {
            return false;
        }

        const ids = prevIds.filter(x => currentIds.includes(x));
        if (ids.length === prevIds.length) {
            return true;
        }

        return false;
    }

    public onResized(event: ResizedEvent) {
        this.map.invalidateSize(true);
    }

    private async handleCurrentIntense(val) {
        const firstRun = !this.currentIntense;
        if (val) { // caching
            this.currentIntense = val;

        }
        if (this.currentIntense && this.currentIntense.tappe && Array.isArray(this.currentIntense.tappe)
            && this.currentIntense.tappe[0].osts && Array.isArray(this.currentIntense.tappe[0].osts)) {

            const osts = [];
            this.currentIntense.tappe.forEach((element, $idx) => {
                if (element.osts && element.osts.length) {
                    element.osts.forEach(ost => {
                        osts.push({ ost: ost, inCurrentTappa: $idx === this.currentIntense.currentTappa });
                    });
                }
            });

            if (!!this.currentIntenseLayer) {
                this.map.removeLayer(this.currentIntenseLayer);
            }
            if (!!this.currentIntenseTappaLayer) {
                this.map.removeLayer(this.currentIntenseTappaLayer);
            }
            this.currentIntenseLayer = L.featureGroup().addTo(this.map);
            this.currentIntenseTappaLayer = L.featureGroup().addTo(this.map);
            const promises = [];
            for (let i = 0; i < osts.length; i++) {
                const ostItem = osts[i].ost;
                const inCurrentTappa = osts[i].inCurrentTappa;
                if (!this.utils.isOstTratta(ostItem)) {
                    promises.push(this.addElementToLayer(ostItem, inCurrentTappa));
                }
            }
            await Promise.all(promises);
            if (this.currentIntenseTappaLayer.getLayers().length > 0) {
                // this.map.fitBounds(this.currentIntenseTappaLayer.getBounds());
            }


            this.updateNextPoints(this.currentIntense.nextPoints);
            this.drawTappePercorso(this.currentIntense.tappe);

        } else {
            this.updateNextPoints([]);
        }

        if (firstRun) {
            window.setTimeout(() => this.zoomPercorso(), 300);
        }
    }

    patchOldIntense(currentIntense) {
        // rimozione dei vechi ost dall'intense, patch da rimuovere
        // if (currentIntense.tappe && currentIntense.tappe.length && currentIntense.tappe[0].osts.length && !currentIntense.tappe[0].percorso) {

        //     this.store.dispatch(new RemovePercorsoTratto({ indexTratto: 0, indexTappa: 0 }));
        // }
    }

    private currentPercorso() {
        return this.currentIntense.tappe[this.currentIntense.currentTappa].percorso;
    }

    private esistePercorso() {
        return this.currentIntense
            && Array.isArray(this.currentIntense.tappe)
            && this.currentIntense.currentTappa != null
            && this.currentIntense.tappe.length > this.currentIntense.currentTappa
            && this.currentPercorso();
    }

    private async updateNextPoints(punti: PuntoPercorsoSuggerito[]) {

        this.nextPointsLayer.clearLayers();
        let ultimopuntoSuggerito: PuntoPercorsoSuggerito = null;
        if (this.esistePercorso()) {
            ultimopuntoSuggerito = this.percorso.ultimoPuntoPercorso(this.currentPercorso());
        } else { return; }

        if (!this.firstChoose()) {
            this.removeSTartPoints(null);
        }

        if (punti && Array.isArray(punti) && ultimopuntoSuggerito.punto) {
            for (const punto of punti) {
                const isFar = !!ultimopuntoSuggerito && (this.utils.distance(ultimopuntoSuggerito.punto, punto.punto) > config.DISTANZA_MASSIMA_AGGIUNTA_NONSEGNALATA_KM);
                const p = await this.layerMaker.createNextPointMarker(punto, isFar, nxt => { this.mousEnterNextPoint(nxt); }, () => this.mouseLeaveNextPoint());
                this.nextPointsLayer.addLayer(p);
            }
        }

        if (ultimopuntoSuggerito && ultimopuntoSuggerito.punto) {
            this.drawEndPercorso(ultimopuntoSuggerito.punto);
        }

        this.nextPointsLayer.bringToFront();
    }

    private mouseClickStartPoint(ost: Ost) {
        // zoom to ost
        this.zoomToOst(ost);
    }

    private zoomToOst(ost) {
        this.utils.getOstGeometry(ost).then(geom => {
            const gl = L.geoJSON(geom);
            this.map.fitBounds(gl.getBounds());
        });
    }

    private mouseEnterStartPoint(ost: Ost, feature) {
        this.matSnack.openFromComponent(SnackbarComponent, { data: ost });
        this.highlightFeature(feature);

    }

    private mouseLeaveStartPoint(feature, color) {
        this.matSnack.dismiss();
        this.highlightFeature(feature, color);
    }

    private highlightFeature(feature, color = null) {
        const colorLine = color ? color : config.HIGHLIGHT_COLOR;
        feature.setStyle({ color: colorLine });
    }

    private mousEnterNextPoint(p: PuntoPercorsoSuggerito) {
        this.mouseLeaveNextPoint();
        const ultimopunto = this.percorso.ultimoPuntoPercorso(this.currentPercorso());
        this.percorso.getSegmento(p.ostProvenienza, ultimopunto.punto, p.punto, true).then(geometry => {
            const preview = L.geoJSON(geometry, {
                style: function () {
                    return {
                        'color': config.PREVIEW_COLOR,
                        'weight': config.PREVIEW_WEIGHT,
                        'dashArray': config.PREVIEW_DASH_ARRAY
                    };
                }
            });
            this.previewLayer.addLayer(preview);
        });

        if (p.ostConnessi && p.ostConnessi.length) {

            this.matSnack.openFromComponent(SnackbarComponent, { data: p.ostConnessi });
        } else if (p.ostProvenienza) {
            this.matSnack.openFromComponent(SnackbarComponent, { data: p.ostProvenienza });
        }
    }

    private mouseLeaveNextPoint() {
        this.previewLayer.eachLayer(l => {
            this.previewLayer.removeLayer(l);
        });
        this.matSnack.dismiss();
    }

    private detailsView(isDetail) {
        // if(isDetail){
        this.normalContainerLayer.removeLayer(this.resultsLayer);
        this.normalContainerLayer.removeLayer(this.nextPointsLayer);
        // }
        if (!isDetail) {
            this.resultsLayer.addTo(this.normalContainerLayer);
            this.nextPointsLayer.addTo(this.normalContainerLayer);
        } else {
            // zoom su percorso
            this.zoomPercorso();
        }
        // this.percorsoLayer; //sempre visibile
        // this.highlightLayer;//sempre visibile
    }

    private zoomPercorso() {
        if (this.percorsoLayer.getLayers().length) { this.map.fitBounds(this.percorsoLayer.getBounds()); }
    }

    private highlight(elem) {

        if (elem && elem.tratto) {
            const geom = elem.tratto.geometry ? elem.tratto.geometry : elem.tratto;
            const highlight = L.geoJSON(geom, {
                style: function () {
                    return {
                        color: config.HIGHLIGHT_COLOR,
                        weight: config.HIGHLIGHT_WEIGHT,
                        dashArray: config.HIGHLIGHT_DASH_ARRAY,
                        opacity: config.HIGHLIGHT_OPACITY,
                    };
                }
            });
            this.highlightLayer.clearLayers();
            this.highlightLayer.addLayer(highlight);
        } else if (elem && elem.type) { // Controllo debole per il tipo OST, da migliorare
            const e2 = this.utils.clone(elem);
            this.utils.getOstGeometry(e2).then(geom => {
                const center = this.utils.getCoords(geom);
                const highlight = L.circleMarker([center[1], center[0]],
                    {
                        radius: config.HIGHLIGHT_RADIUS,
                        color: config.HIGHLIGHT_COLOR,
                        fillOpacity: config.HIGHLIGHT_OPACITY,
                        stroke: 0
                    });
                this.highlightLayer.clearLayers();
                this.highlightLayer.addLayer(highlight);
            });
        } else {
            this.highlightLayer.clearLayers();
        }
    }


    private drawTappePercorso(tappe) {
        this.percorsoLayer.eachLayer(l => {
            this.percorsoLayer.removeLayer(l);
        });
        for (const tappa of tappe) {
            this.drawPercorso(tappa.percorso, tappe[this.currentIntense.currentTappa] === tappa);
        }
    }

    private drawPercorso(percorso: Percorso, isSelected: boolean) {
        // console.log('TCL: drawPercorso -> percorso', percorso, isSelected);
        if (percorso && percorso.tratti) {
            this.drawPointPercorso(percorso.inizio);
            percorso.tratti.forEach((tratto, idx) => {
                this.drawTrattoPercorso(tratto, isSelected);
                if (idx !== percorso.tratti.length - 1) {
                    this.drawPointPercorso(tratto.ultimoPunto);
                }
            });
        }
        this.percorsoLayer.bringToBack();
        if (!isSelected) {
            this.percorsoLayer.bringToBack();
        }
    }

    private drawPointPercorso(punto) {
        const mrk = this.layerMaker.createPercorsotPointMarker(this.utils.featureToArray(punto));
        if (mrk) { this.percorsoLayer.addLayer(mrk); }
    }

    private drawTrattoPercorso(tratto, isSelected) {
        const l = L.geoJSON(tratto.tratto, {
            style: function () {
                return {
                    'color': isSelected ? config.PERCORSO_INTAPPA_COLOR : config.PERCORSO_FUORITAPPA_COLOR,
                    'weight': tratto.ost ? config.PERCORSO_INOST_WEIGHT : config.PERCORSO_FUORIOST_WEIGHT,
                    'dashArray': tratto.ost ? config.PERCORSO_INOST_DASHARRAY : config.PERCORSO_FUORIOST_DASHARRAY
                };
            }
        });
        this.percorsoLayer.addLayer(l);
    }

    private drawEndPercorso(pointFeature) {
        const point = this.utils.featureToArray(pointFeature);
        const p = this.layerMaker.createLastPointMarker(point);
        this.nextPointsLayer.addLayer(p);
    }

    private async addElementToLayer(ost: Ost, inCurrentTappa) {
        const markerColor = inCurrentTappa ? config.PERCORSO_MARKER_INTAPPA_COLOR : config.PERCORSO_MARKER_FUORITAPPA_COLOR;
        const geoJSONobj = await this.utils.getOstGeometry(ost);
        const layer = this.layerMaker.createOstMarker(geoJSONobj, markerColor, ost);
        const toAddLayer = inCurrentTappa ? this.currentIntenseTappaLayer : this.currentIntenseLayer;
        toAddLayer.addLayer(layer);
    }

    private createEndOfLinePoli(geoJSONobj, color) {
        const center = this.utils.getLastCoords(geoJSONobj);
        const direction = this.utils.calculateDirection(geoJSONobj, false);
        const circle = L.semiCircle([center[1], center[0]],
            { radius: 10, fillOpacity: 1, fillColor: color, color: 'black', weight: '1' }).setDirection(direction + 180, 180);
        return circle;
    }


    private firstChoose() {
        const tappaAttuale = this.currentIntense.tappe[this.currentIntense.currentTappa];
        if (tappaAttuale && tappaAttuale.percorso && tappaAttuale.percorso.inizio != null) {
            return false;
        } else { return true; }
    }


    private handleResults(val, hardreset) {

        this.showOnZoomLevel();

        if (val && Array.isArray(val)) {
            const ids = [];
            const startIds = Object.getOwnPropertyNames(this.layerMapById);
            let ostList = [];
            ostList = this.utils.clone(val);

            this.resultsLayer.eachLayer((layer) => {
                const valIdx = ostList.findIndex(o => o.id === layer.id);
                if (valIdx === -1 || hardreset) {
                    this.resultsLayer.removeLayer(layer);
                    this.removeSTartPoints(layer.id);
                } else {
                    ostList.splice(valIdx, 1);
                }
            }, this);
            if (ostList.length) {
                for (let i = 0; i < ostList.length; i++) {
                    const ost: Ost = ostList[i];
                    this.addOst(ost);
                }
            }
        }
    }


    private removeSTartPoints(ostId) {
        this.startPointsLayer.eachLayer((layer) => {
            if (ostId === layer.ostid || !ostId) {
                this.startPointsLayer.removeLayer(layer);
            }
        });

    }

    private async addOst(ost) {
        const ostGeoJSON = await this.utils.getOstGeometry(ost);
        // ostGeoJSON['properties'] = { id: ost.id };
        // const index = await this.utils.calculateIndex(this.currentIntense, ost);
        let index = -1;
        if (!this.utils.isOstTratta(ost)) {
            if (this.esistePercorso()) {
                index = this.utils.isNearPercorso(ostGeoJSON, this.currentPercorso()) ? 1 : -1;
            }
        }
        const features = this.createFeatures(ost, ostGeoJSON, index);
        const group = L.featureGroup(features);
        group['id'] = ost.id;
        this.resultsLayer.addLayer(group);
    }

    private createFeatures(ost, ostGeoJSON, index) {
        // non vengono mostrati gli attrattori troppo lontani
        if (index < 0 && !this.utils.isOstTratta(ost) && this.firstChoose()) { return; }

        const colorMarker = index > -1 ? config.MARKER_NEAR_COLOR : config.MARKER_DISTANT_COLOR;
        const colorLine = this.layerMaker.colorForOst(ost);
        const marker = L.AwesomeMarkers.icon({
            icon: this.layerMaker.getAwesomeIcon(ost),
            prefix: 'fa',
            markerColor: colorMarker,
            extraClasses: index > -1 ? '' : 'map-icon-far',
            zIndexOffset: index > -1 ? 1500 : null,
        });
        const options = {
            'weight': 4,
            clickTolerance: 100,
            color: colorLine,
            pointToLayer: function (_, latlng) {
                return L.marker(latlng, { icon: marker });
            }
        };
        const features = [];
        // ostGeoJSON['properties'] = { id: ost.id };
        const feature = L.geoJson(ostGeoJSON, options);

        // popup solo per gli attrattori
        if (!this.utils.isOstTratta(ost)) { feature.bindPopup(fl => this.createPopup(ost, feature, index), { maxWidth: 400 }); }

        feature.on('mouseover', ($event) => {
            this.matSnack.openFromComponent(SnackbarComponent, { data: ost });
            this.highlightFeature(feature);
        });
        feature.on('mouseout', ($event) => {
            this.matSnack.dismiss();
            this.highlightFeature(feature, colorLine);
        });
        feature.on('click', ($event) => {
            this.addStartMarker(ostGeoJSON, ost, feature, colorLine);
        });






        features.push(feature);
        if (this.utils.isOstTratta(ost)) {
            if (config.SHOW_OST_HEADS) {
                const start = this.layerMaker.createStartOfLinePoli(ostGeoJSON, colorLine);
                start.id = ost.id;
                const end = this.layerMaker.createEndOfLinePoli(ostGeoJSON, colorLine);
                end.id = ost.id;
                features.push(start);
                features.push(end);
            }

            // if (this.firstChoose()) {
            //     const startMKR = this.layerMaker.createStartMarker(
            //         ostGeoJSON, true, ost,
            //         clickOst => { this.mouseClickStartPoint(clickOst); },
            //         startOst => { this.mouseEnterStartPoint(startOst, feature); },
            //         () => { this.mouseLeaveStartPoint(feature, colorLine); });
            //     const endMKR = this.layerMaker.createStartMarker(
            //         ostGeoJSON, false, ost,
            //         clickOst => { this.mouseClickStartPoint(clickOst); },
            //         startOst => { this.mouseEnterStartPoint(startOst, feature); },
            //         () => { this.mouseLeaveStartPoint(feature, colorLine); });
            //     this.startPointsLayer.addLayer(startMKR);
            //     this.startPointsLayer.addLayer(endMKR);
            // }

        }

        return features;
    }

    private addStartMarker(ostGeoJSON, ost, feature, colorLine) {
        this.startPointsLayer.clearLayers();

        if (this.firstChoose()) {
            const startMKR = this.layerMaker.createStartMarker(
                ostGeoJSON, true, ost,
                clickOst => { this.mouseClickStartPoint(clickOst); },
                startOst => { this.mouseEnterStartPoint(startOst, feature); },
                () => { this.mouseLeaveStartPoint(feature, colorLine); }
            );
            const endMKR = this.layerMaker.createStartMarker(
                ostGeoJSON, false, ost,
                clickOst => { this.mouseClickStartPoint(clickOst); },
                startOst => { this.mouseEnterStartPoint(startOst, feature); },
                () => { this.mouseLeaveStartPoint(feature, colorLine); }
            );
            this.startPointsLayer.addLayer(startMKR);
            this.startPointsLayer.addLayer(endMKR);
        }
    }


    private createPopup(ost: Ost, feature, index: number) {
        if (!this.utils.isOstTratta(ost)) {
            const popupEl: NgElement & WithProperties<AddPoiPopupComponent> = document.createElement('app-add-poi-popup') as any;
            popupEl.addEventListener('closed', () => { document.body.removeChild(popupEl); });
            document.body.appendChild(popupEl);
            popupEl.ost = ost;
            popupEl.feature = feature;
            popupEl.index = index;
            return popupEl;
        } else {
            const popupEl: NgElement & WithProperties<AddTrattoPopupComponent> = document.createElement('app-add-tratto-popup') as any;
            popupEl.addEventListener('closed', () => document.body.removeChild(popupEl));
            document.body.appendChild(popupEl);
            popupEl.ost = ost;
            popupEl.index = index;
            popupEl.feature = feature;
            return popupEl;
        }
    }

    public openPop(ost: Ost) {
        if (this.utils.isOstTratta(ost)) {
            this.zoomToOst(ost);
        } else {
            this.resultsLayer.eachLayer(function (layer) {
                if (layer['id'] === ost.id) {
                    // console.log('---Got layer', layer, ost);
                    layer.eachLayer(function (sublayer) {
                        sublayer.openPopup();
                    });
                }
            }, this);
        }
    }



    // ********************************************************************
    // ******************* REGION AND CLUSTERS ****************************
    // ********************************************************************

    showOnZoomLevel() {
        if (this.isDetailsZoom() || this.esistePercorso()) {
            this.map.removeLayer(this.regionContainerLayer);
            this.map.addLayer(this.normalContainerLayer);
        } else {
            this.map.removeLayer(this.normalContainerLayer);
            this.map.addLayer(this.regionContainerLayer);

        }

    }


    private updateClusters = (values) => {

        if (this.isDetailsZoom()) { return; }


        if (values) {
            this.clusters = [];
            const promises = [];
            for (const val of values) {
                const p = this.utils.getRegionData(val.key).then(info => {
                    this.clusters.push({ geometry: info.geometry, center: info.center, count: val.count });
                });
                promises.push(p);
            }

            Promise.all(promises).then(() => {
                this.addClusterLayer();
            });

        }


    }

    private zoomToRegion = (polygon) => {
        this.map.fitBounds(polygon.getBounds());
    }

    private addClusterLayer = () => {

        this.clearCluster();

        for (const cluster of this.clusters) {
            const pol = L.geoJSON(cluster.geometry);

            const markerCluster = this.layerMaker.createRegionClusterMarker(cluster.center, cluster.count);
            this.regionContainerLayer.addLayer(markerCluster);
            markerCluster.on('click', a => {
                this.zoomToRegion(pol);
            });
            this.clusterLayers.push(markerCluster);

        }

        this.addRegionLayer();
    }

    private addRegionLayer() {

        this.appDataSelector.regionArray$().subscribe(regs => {
            if (!this.isDetailsZoom() && regs && this.regionLayers.length === 0) {
                for (const regName of regs) {
                    this.utils.getRegionData(regName).then(info => {

                        let clust = this.clusters.find(c => this.utils.compareGeneralObj(c.center, info.center));
                        if (!clust) { clust = { count: 0 }; }

                        const pol = this.layerMaker.createRegionPolygon(info.geometry, clust.count);

                        if (this.regionContainerLayer) {
                            pol.addTo(this.regionContainerLayer);
                        }
                        this.regionLayers.push(pol);
                        pol.on('click', a => {
                            this.zoomToRegion(pol);
                        });
                    });
                }
            }
        });
    }

    private clearRegion = () => {
        this.regionLayers.forEach(layer => {
            this.regionContainerLayer.removeLayer(layer);
        });
        this.regionLayers = [];
    }

    private clearRegionAndCluster = () => {
        this.clearRegion();
        this.clearCluster();
    }

    private clearCluster = () => {
        for (let i = this.clusterLayers.length - 1; i >= 0; i--) {
            const layer = this.clusterLayers[i];
            this.regionContainerLayer.removeLayer(layer);
            this.clusterLayers.splice(i, 1);
        }
        // this.clusterLayers = [];
    }

    private isDetailsZoom = () => {
        return this.map.getZoom() > 9;
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();

        // this.map.remove(this.baseLayers);
        // this.map.remove(this.regionContainerLayer);
        // this.map.remove(this.normalContainerLayer);
        // this.map.remove(this.currentIntenseLayer);
        // this.map.remove(this.currentIntenseTappaLayer);
        // this.map.remove(this.regionContainerLayer);
        // this.map.remove(this.normalContainerLayer);
        this.map.off();
    }
}

