import { Injectable, } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
    providedIn: 'root'
})
export class AppDataRequests {

    constructor(private apollo: Apollo) { }

    ostInfo = gql`
    fragment OstInfo on OST {
        id
        geometry  {
           type
           value
              }
    }`;

    schedaInfoFragment = gql`
        fragment IntenseInfo on Intense {
            id
            geometry {
               type
               value
            }
        }`;

    fetchAllRegioni$() {
        const querySearch = gql`
            query {
                queryRegioni(page: 0, count: 100){
                  regioni{
                    name
                    geometry{
                      value
                    }
                    centroide{
                      value
                    }
                  }
                }
               }
            `;
        return this.apollo.watchQuery<any>({
            query: querySearch,
        }).valueChanges;
    }

    fecthAllSchede$() {
        const querySearch = gql`
        query {
            querySchedeIntense(count:99) {
                 schedeIntense{
                ...IntenseInfo
                }
            }
        }${this.schedaInfoFragment}`;

        return this.apollo.watchQuery<any>({
            query: querySearch,
        }).valueChanges;
    }

    fecthAllOst$() {
        const querySearch = gql`
        query {
            queryOst(count: 9999) {
                 ost{
                    ...OstInfo
                }
            }
        }${this.ostInfo}`;

        return this.apollo.query<any>({
            query: querySearch,
        });
    }

    fetchPeriods$() {
        const querySearch = gql`
            query {
                queryFestivita{
                  festivita{
                    name
                    data_festivita
                  }
                }
            }
            `;
        return this.apollo.watchQuery<any>({
            query: querySearch,
        }).valueChanges;
    }
}
